import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-section-dialog',
  templateUrl: './add-section-dialog.component.html',
  styleUrls: ['./add-section-dialog.component.css']
})
export class AddSectionDialogComponent implements OnInit {
  name = '';
  addSectorForm: FormGroup;
  public isInValid !: boolean; 
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.addSectorForm = new FormGroup({
      'name' : new FormControl(null, Validators.required),
    })
  }

  add() {
    console.log("added!");
  }

}
