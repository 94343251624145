<div class="header">
    <div style="width: 140px;"></div>
    <a [routerLink]="['/admin']">
        <img src="../../../assets/images/pse_logo.jpg" alt="PSE Logo" class="pse-header-logo"/>
    </a>
    <div class="flexed-items" style="width: 140px;">
        <a [routerLink]="['/main']">
            <img src="../../../assets/images/Icons/search-talents.svg" alt="Zur Applikation" class="log-out-btn" matTooltip="Zur Applikation">
        </a>
        <a [routerLink]="['/admin']">
            <img src="../../../assets/images/Icons/admin.png" alt="admin panel" class="log-out-btn" matTooltip="Admin">
        </a>
        <a [routerLink]="['/admin/reports']">
            <img src="../../../assets/images/Icons/statistics.png" alt="Reports" class="log-out-btn" matTooltip="Reports">
        </a>
        <a [routerLink]="['/info']">
            <img src="../../../assets/images/Icons/info.png" alt="info" class="log-out-btn" matTooltip="Informationen">
          </a>
        <div (click)="logout()">
            <img src="../../../assets/images/Icons/log-out.png" alt="Ausloggen" class="log-out-btn" matTooltip="Ausloggen">
        </div>
    </div>
    
</div>