<div class="centered">
    <div class="page-a4">
        <!-- <div class="new-icon"></div> -->
        <div class="header">
            <div class="options"></div>
            <img src="../../assets/images/pse_logo.jpg" alt="PSE Logo" class="logo-image">
            <div class="options">
                <div *ngIf="!isFav" class="favorite-icon" matTooltip="Talent speichern" (click)="addToFavorites()"></div>
                <div *ngIf="isFav" class="favorite-icon-filled" matTooltip="Talent entfernen" (click)="removeFromFavorites()"></div>
                <div class="get-to-know-icon" [class.get-to-know-icon-filled]="isConnected" matTooltip="{{ !isConnected ? 'Kennenlernen' : 'Anfrage zurückziehen'}}" (click)="connectT()"></div>
                <div class="close-icon" (click)="close()" matTooltip="Schliessen"></div>
            </div>  
        </div>

        <div class="box-wrapper">
            <div class="box">
                <p class="f-10 bold-txt">Funktion</p>
                <p class="f-10">{{talent?.current_position.replaceAll('/', '') || ""}}</p>
            </div>
            <div class="box">
                <p class="f-10 bold-txt">Alter</p>
                <p class="f-10">{{age || ""}}</p>
            </div>
            <div class="box">
                <p class="f-10 bold-txt">Wohnort</p>
                <p class="f-10">{{talent?.city || ""}}</p>
            </div>
            <div class="box">
                <p class="f-10 bold-txt">Verfügbarkeit</p>
                <p class="f-10">{{talent?.availability || ""}}</p>
            </div>
            <div class="box">
                <p class="f-10 bold-txt">Jahressalär (100%)</p>
                <p class="f-10">{{talent?.salary || ""}}</p>
                
            </div>
            <div class="box">
                <p class="f-10 bold-txt">Pensum</p>
                <p class="f-10">{{(talent?.employment_rate * 100) + "%" || ""}}</p>
            </div>
        </div>

        <!-- <div class="box lists-box">
            <div class="half-width">
                <p class="bold-txt full-width">Berufliche Fähigkeiten</p>
                <ul *ngIf="talent?.skills?.length" class="one-pager-li">
                    <li class="dif-font" *ngFor="let skill of talent?.skills">{{skill.name}}</li>
                </ul>
                <p *ngIf="!talent?.skills?.length" style="color: #808080" class="dif-font full-width">keine beruflichen Fähigkeiten</p>
            </div>
            <div class="half-width">
                <p class="bold-txt full-width">Persönliche Fähigkeiten</p>
                <ul *ngIf="talent?.personal_skills?.length" class="one-pager-li">
                    <li class="dif-font" *ngFor="let skill of talent?.personal_skills">{{skill.name}}</li>
                </ul>
                <p *ngIf="!talent?.personal_skills?.length" style="color: #808080" class="dif-font full-width">keine persönlichen Fähigkeiten</p>
            </div>
        </div>



        <div class="text">
            <p class="bold-txt mb-15">Über mich</p>
            <p class="dif-font break-words">{{description}}</p>
            <p *ngIf="!talent?.description?.length" style="color: #808080" class="dif-font full-width">keine Beschreibung gefunden</p>
        </div> -->

        <div>
            <div>
                <p class="bold-txt full-width f-11 pt-50 pb-12">Qualifikationen</p>
                <ul *ngIf="talent?.skills?.length" class="one-pager-li">
                    <li class="f-10 pb-2 ml-8" *ngFor="let skill of talent?.skills">
                        <span class="pl-5"></span>
                        {{skill.name}}
                    </li>
                </ul>
                <p *ngIf="!talent?.skills?.length" style="color: #808080" class="dif-font full-width f-10">keine beruflichen Fähigkeiten</p>
            </div>
            <div>
                <p class="bold-txt full-width f-11 pt-30 pb-12">Erfahrungen</p>
                <ul *ngIf="talent?.personal_skills?.length" class="one-pager-li">
                    <li class="f-10 pb-2 ml-8" *ngFor="let skill of talent?.personal_skills">
                        <span class="pl-5"></span>
                        {{skill.name}}
                    </li>
                </ul>
                <p *ngIf="!talent?.personal_skills?.length" style="color: #808080" class="dif-font full-width f-10">keine persönlichen Fähigkeiten</p>
            </div>
        </div>

        <p class="bold-txt f-11 pt-30 pb-0">Kompetenzen & Persönlichkeit</p>
        <div class="lists-box">
            <ul class="one-pager-li-description">
                <ng-container *ngFor="let data of rowsData">
                    <li *ngIf="data.charAt(0) !== '['" class="dif-font ml-8 marker">
                        <p class="f-10 pl-5 bold-txt pt-15 pb-2">{{getRowData(data)}}</p>
                    </li>
                    <li *ngIf="data.charAt(0) === '['" class="dif-font">
                        <p class="f-10 pt-0 pb-0">{{getRowData(data)}}</p>
                    </li>
                </ng-container>
                <!-- <li class="dif-font" *ngFor="let data of rowsData" [class.no-marker]="data.charAt(0) === '['" [class.ml-8]="data.charAt(0) !== '['">
                    <p  
                        class="f-10" 
                        [class.pl-5]="data.charAt(0) !== '['"
                        [class.bold-txt]="data.charAt(0) !== '['" 
                        [class.pt-15]="data.charAt(0) !== '['" 
                        [class.pb-0]="data.charAt(0) !== '['"
                        [class.pt-0]="data.charAt(0) === '['"
                        [class.pb-0]="data.charAt(0) === '['"
                    >
                        {{getRowData(data)}}
                    </p>
                </li> -->
            </ul>
            <p *ngIf="!rowsData?.length" style="color: #808080;line-height: 15px; margin-top: 12px;" class="dif-font full-width f-10">{{description}}</p>
            <p *ngIf="!rowsData?.length && !description" style="color: #808080" class="dif-font full-width f-10">keine Kompetenzen & Persönlichkeit</p>
        </div>


    </div>
</div>