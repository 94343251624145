<div class="header">
    <div style="width: 250px;">
    </div>
    <a href="/main">
      <img src="../../../assets/images/pse_logo.jpg" alt="PSE Logo" class="pse-header-logo"/>
    </a>
    <div class="flexed-items" style="width: 250px;">
      <a [routerLink]="['/saved-searches']">
        <img src="../../../assets/images/Icons/bookmark-grey.png" alt="bookmark" class="icon-btn" matTooltip="Gespeicherte Suchen">
      </a>
      <a [routerLink]="['/favorited-talents']">
        <img src="../../../assets/images/Icons/favourites-grey.png" alt="favorites" class="icon-btn" matTooltip="Meine Favoriten">
      </a>
      <a [routerLink]="['/connections']">
        <img src="../../../assets/images/Icons/hand-shake-hover.png" alt="connections" class="icon-btn handshake" matTooltip="Angefragte Talente">
      </a>
      <a [routerLink]="['/admin']" *ngIf="user.role === 'admin'">
        <img src="../../../assets/images/Icons/admin.png" alt="admin panel" class="icon-btn" matTooltip="Admin">
      </a>
      <a [routerLink]="['/info']">
        <img src="../../../assets/images/Icons/info.png" alt="info" class="icon-btn" matTooltip="Informationen">
      </a>
      <div (click)="logout()">
        <img src="../../../assets/images/Icons/log-out.png" alt="Log out" class="icon-btn" matTooltip="Ausloggen">
      </div>
    </div>
  </div>