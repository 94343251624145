import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { AuthService } from '../../_services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  public showPassword = false;
  public showConfirmPassword = false;
  public submitted = false;
  public token = ''; 
  public email = ''; 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(resp => {
      if (resp.token && resp.email) {
        this.token = resp.token;
        this.email = resp.email;
      } else {
        alert('wrong url');
      }
    });
    this.resetPasswordForm = new FormGroup({
      'password' : new FormControl(null, Validators.required),
      'password_confirmation' : new FormControl(null, Validators.required),
    })
  }

  togglePasswordVisibility(type) {
    if(type == 'password') {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }  
  }

  resetPassword() {
    if(this.resetPasswordForm.valid){
      if (!this.arePasswordsSame()) {
        alert('passwords do not match');
        return;
      }
      
      this.authService.resetPassword({email: this.email, token: this.token,
        password: this.resetPasswordForm.value.password, password_confirmation: this.resetPasswordForm.value.password_confirmation})
        .subscribe(response => {
          this.router.navigate(['/login']);
        });

    } else {
      this.submitted = true;
    }
  }

  arePasswordsSame() {
    return this.resetPasswordForm.value.password === this.resetPasswordForm.value.password_confirmation;
  }
}
