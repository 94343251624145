<div class="centered">
  <!-- [ngStyle]="isAlertBeingShown ? {minHeight: '80vh'} : {minHeight: '100vh'}" -->
  <div class="greeting-box">
    <!-- <h3 class="title">Willkommen zu</h3> -->
    <img src="../../assets/images/pse_logo.jpg" alt="PSE Logo" class="pse-logo-img"/>
  </div>

  <!-- <h1>Test environment</h1> -->
  <form [formGroup]="userForm">
    <div class="input-box-wrapper">
      <label class="label-item">E-Mail</label>
      <input type="text" formControlName="email" class="input-item" [class.required-input]="(submitted && userForm.get('email').invalid) || (userForm.get('email').touched && userForm.get('email').invalid)">
        <div class="required-text" *ngIf="(submitted && userForm.get('email').invalid) || (userForm.get('email').touched && userForm.get('email').invalid)">
          E-Mail ist erforderlich
        </div>
    </div>
    <div class="input-box-wrapper">
      <label class="label-item">Passwort</label>
      <div class="password-container">
        <input type="{{showPassword ? 'text' : 'password'}}" formControlName="password" class="input-item" [class.required-input]="(submitted && userForm.get('password').invalid) || (userForm.get('password').touched && userForm.get('password').invalid)">
        <div class="show-password" [class.show]="showPassword" (click)="togglePasswordVisibility()"></div>
      </div>
      
      <div class="required-text" *ngIf="(submitted && userForm.get('password').invalid) || (userForm.get('password').touched && userForm.get('password').invalid)">
        Passwort ist erforderlich
      </div>
    </div>
    <div class="right-aligned-items">
      <a [routerLink]="['/forgot-password']">Passwort vergessen?</a>
    </div>
    <div style="height: 30px">
      <p class="required-text" *ngIf="incorrectData">Falscher Benutzername oder Passwort</p>
    </div>
    <button type="submit" class="btn-elem mb-2" (click)="login()">Login</button>
    <!-- <div class="v-centered-items" *ngIf="isLoggingIn">
      <img src="../../assets/images/loading.gif" alt="loading" style="width: 30px; height: 30px; margin-bottom: 15px;">
    </div> -->
  </form> 
  <p class="text">Haben Sie kein Konto? <a [routerLink]="['/register']" class="link-item">Hier registrieren!</a></p>
  <a [routerLink]="['/info']">Informationen</a>
</div>