import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class FileService {
    constructor(private http: HttpClient) {
    }

    uploadFile(data) {
        return this.http.post<any>(`${environment.apiUrl}/upload`, data);
    }
}