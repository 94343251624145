import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  user = JSON.parse(localStorage.getItem('user'));

  ngOnInit(): void {
    console.log(localStorage.getItem('user'));
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['']);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }, err => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.router.navigate(['']);
    });
    this.router.navigate(['']);
  }

}
