<div class="centered-flex">
    <div>
        <img src="../../../assets/images/pse_logo.jpg" alt="">
        <p class="text big-text">Passwort zurücksetzen</p>

        <form [formGroup]="resetPasswordForm">
            <div class="input-box-wrapper">
                <label class="label-item">Passwort</label>
                <div class="password-container">
                <input type="{{showPassword ? 'text' : 'password'}}" formControlName="password" class="input-item" required [class.required-input]="(submitted && resetPasswordForm.get('password').invalid) || (resetPasswordForm.get('password').touched && resetPasswordForm.get('password').invalid)">
                <div class="show-password" [class.show]="showPassword" (click)="togglePasswordVisibility('password')"></div>
                </div>
                <div class="required-text" *ngIf="(submitted && resetPasswordForm.get('password').invalid) || (resetPasswordForm.get('password').touched && resetPasswordForm.get('password').invalid)">
                Passwort ist erforderlich
                </div>
            </div>
            <div class="input-box-wrapper">
                <label class="label-item">Passwortbestätigung</label>
                <div class="password-container">
                <input type="{{showConfirmPassword ? 'text' : 'password'}}" formControlName="password_confirmation" class="input-item" required [class.required-input]="(submitted && resetPasswordForm.get('password_confirmation').invalid) || (resetPasswordForm.get('password_confirmation').touched && resetPasswordForm.get('password_confirmation').invalid)">
                <div class="show-password" [class.show]="showConfirmPassword" (click)="togglePasswordVisibility('confirmpassword')"></div>
                </div>
                <div class="required-text" *ngIf="(submitted && resetPasswordForm.get('password_confirmation').invalid) || (resetPasswordForm.get('password_confirmation').touched && resetPasswordForm.get('password_confirmation').invalid)">
                Passwortbestätigung ist erforderlich
                </div>
            </div>
            <button type="submit" class="btn-el" (click)="resetPassword()">zurücksetzen</button>
        </form>
    </div>
</div>