import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public code = '';
  public submitted = false;
  public isVerifying = false;
  public isRegistering = false;
  userForm: FormGroup;
  public showPassword = false;
  public showConfirmPassword = false;
  public activePage = "register";
  notSamePasswords = false;
  emailExists = false;
  isIncorrectCode = false;
  isVerificationCodeResend = false;
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) { 
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'first_name' : new FormControl(null, Validators.required),
      'last_name' : new FormControl(null, Validators.required),
      'company_name' : new FormControl(null, Validators.required),
      'email' : new FormControl(null, Validators.required),
      'password' : new FormControl(null, [Validators.required,Validators.minLength(6)]),
      'password_confirmation' : new FormControl(null, Validators.required),
      // 'subscription_plan' : new FormControl(null, Validators.required)
    });
  }

  register() {
    this.submitted = true;
    if(this.userForm.valid) {
      if(this.userForm.value.password !== this.userForm.value.password_confirmation) {
        this.notSamePasswords = true;
      } else {
        this.notSamePasswords = false;
        this.isRegistering = true;
        this.authService.sendVerification(this.userForm.value).subscribe(response => {
          if (response.exists == false) {
            this.activePage = "verification-code";
          } else {
            this.emailExists = true;
            this.isRegistering = false;
          }
        });
      }
    }
  }

  togglePasswordVisibility(type) {
    if(type == 'password') {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }  
  }

  onCodeChanged(code: string) {
    this.isIncorrectCode = false;
    this.isVerificationCodeResend = false;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.code = code;
    // this.activePage = "verifying";
    this.verify();

  }

  verify() {
    // this.activePage = "verifying";
    this.isVerifying = true;
    this.isIncorrectCode = false;
    this.authService.register({first_name: this.userForm.value.first_name,last_name: this.userForm.value.last_name, email: this.userForm.value.email,password: this.userForm.value.password, password_confirmation: this.userForm.value.password_confirmation,company_name: this.userForm.value.company_name, code: this.code}).subscribe(response => {
      if (response) {
          this.activePage = "verifying";
          this.isVerifying = false;
      }
    }, errorResponse => {
      this.isVerifying = false;
      this.isIncorrectCode = true;
      this.isVerificationCodeResend = false;
      console.log(errorResponse.error.message);
    });
  }

  resend() {
    this.isIncorrectCode = false;
    this.isVerificationCodeResend = true;
    this.authService.sendVerification(this.userForm.value).subscribe(response => {
      if (response) {
        this.activePage = "verification-code";
      }
    });
    // this._snackBar.open('Es wurde ein neuer Code angefordert. Die Zustellung kann (auf Grund Ihrer E-Mail-Sicherheitseinstellungen) einige Zeit in Anspruch nehmen.', '', {
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    //   duration: 3 * 10000,
    // });
  }
}