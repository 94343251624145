import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrowserAlertDialogComponent } from '../app/dialogs/browser-alert-dialog/browser-alert-dialog.component';
import * as bowser from 'bowser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showBanner = false;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.alertForNonChrome();
  }

  isChrome(): boolean {
    return bowser.getParser(navigator.userAgent).getBrowserName().toLowerCase() === 'chrome';
  }

  alertForNonChrome(): void {
    const alertShownBefore = localStorage.getItem('browserAlertShown');
    if (!alertShownBefore) {
      if (!this.isChrome()) {
        this.showBanner = true;
        console.log(this.showBanner)
        // localStorage.setItem('browserAlertShown', 'true');
      }
    } else {
      this.showBanner = false;
    }
  }

  openBrowserAlertDialog(): void {
    this.dialog.open(BrowserAlertDialogComponent, {
      disableClose: false,
      panelClass: 'browser-alert-dialog-container'
    });
  }

  closeBanner() {
    this.showBanner = false;
    localStorage.setItem('browserAlertShown', 'true');
  }
}
