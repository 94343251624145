<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p class="text">{{message}}</p>
</div>

<div mat-dialog-actions *ngIf="!confirmationOnly" class="flex-end-item">
    <button mat-button (click)="onDismiss()">Nein</button>
    <button mat-raised-button class="gray-btn" (click)="onConfirm()">Ja</button>
</div>

<div mat-dialog-actions *ngIf="confirmationOnly" class="flex-end-item">
    <button mat-button (click)="onDismiss()" class="btn-el">ok</button>
</div>