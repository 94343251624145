import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class ConnectService {
    constructor(private http: HttpClient) {
    }

    getConnects() {
        return this.http.get<any>(`${environment.apiUrl}/connects`)
    }

    getConnect(id) {
        return this.http.get<any>(`${environment.apiUrl}/connect/${id}`);
    }

    storeConnect(data) {
        return this.http.post<any>(`${environment.apiUrl}/connect`, data);
    }

    deleteConnect(id) {
        return this.http.delete<any>(`${environment.apiUrl}/connect/${id}`);
    }
}
