<app-admin-header></app-admin-header>
<!-- 
<ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="small-sector-image">
	<ngx-dropzone-label>Drop it, baby!</ngx-dropzone-label>
		<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" class="small-sector-image-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
          </ngx-dropzone-image-preview>
</ngx-dropzone> -->

  <div class="body">
    <div class="mt-2"></div>
    <mat-tab-group mat-align-tabs="center" animationDuration="300ms">
        <mat-tab label="Benutzer"> 
            <form action="">
                <div class="flex-between-items mt-4">
                    <h3 class="title">Benutzer</h3>
                    <div class="action-btn-items">
                        <div class="excel-export" matTooltip="Export Users" matTooltipPosition="left" (click)="exportXls()"></div>
                        <div class="search-wrap">
                            <div class="search">
                               <input type="text" class="searchTerm" placeholder="nach Name, Firmenname oder E-Mail suchen" name="search" (keyup)="_search()" [(ngModel)]="search">
                               <button type="submit" class="searchButton"></button>
                            </div>
                        </div>
                        <!-- <button class="new-item-btn" (click)="openAddUser()">
                            Add User
                        </button> -->
                    </div>
                </div>
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th class="column16" (click)="sort('users', 'first_name')">
                                    Vorname
                                    <img *ngIf="sorting == 'asc' && column == 'first_name'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'first_name'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                <th class="column16" (click)="sort('users', 'last_name')">
                                    Nachname
                                    <img *ngIf="sorting == 'asc' && column == 'last_name'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'last_name'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                <th class="column2" (click)="sort('users', 'company_name')">
                                    Firmenname
                                    <img *ngIf="sorting == 'asc' && column == 'company_name'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'company_name'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                <th class="column2" (click)="sort('users', 'email')">
                                    E-Mail
                                    <img *ngIf="sorting == 'asc' && column == 'email'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'email'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                <th class="column2o" (click)="sort('users', 'role')">
                                    Rolle
                                    <img *ngIf="sorting == 'asc' && column == 'role'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'role'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                <th class="column2" (click)="sort('users', 'created_at')">
                                    Registrierung
                                    <img *ngIf="sorting == 'asc' && column == 'created_at'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'created_at'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                <th class="column2" (click)="sort('users', 'subscription')">
                                    Ablaufdatum Abo
                                    <img *ngIf="sorting == 'asc' && column == 'subscription'" class="sorting-icon asc" src="../../assets/images/Icons/asc-sort.svg" alt="">
                                    <img *ngIf="sorting == 'desc' && column == 'subscription'" class="sorting-icon desc" src="../../assets/images/Icons/desc-sort.svg" alt="">
                                </th>
                                
                                <th class="column6"></th>
                                <th class="column6"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of users">
                                <td class="column16">{{user.first_name}}</td>
                                <td class="column16">{{user.last_name}}</td>
                                <td class="column2">{{user.company_name}}</td>
                                <td class="column2">{{user.email}}</td>
                                <td class="column2o">{{user.role == 'admin' ? 'Admin' : 'Kunde'}}</td>
                                <td class="column2">
                                    {{user.created_at | date:'dd.MM.yyyy'}}
                                </td>
                                <td class="column2">
                                   <mat-form-field appearance="fill" style="display: flex; justify-content: flex-start; align-items: center;">
                                        <p style="width: 80px; margin-bottom: 0px;">{{user.subscription | date:'dd.MM.yyyy'}}</p>
                                        <input class="datepicker-input" matInput [matDatepicker]="picker" value="user.subscription" (dateChange)="dateChange($event, user.id)">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td class="column6 col-two-icons">
                                    <button class="btn delete-btn" (click)="deleteuser(user.id)" #tooltip="matTooltip" matTooltip="Benutzer löschen" matTooltipPosition="left"></button>
                                </td>
                                <td class="column6">
                                    <button class="btn edit-btn" (click)="openEditUser(user.id, user.first_name, user.last_name, user.company_name, user.email, user.role)" #tooltip="matTooltip" matTooltip="Benutzer bearbeiten" matTooltipPosition="left"></button>
                                </td>
                            </tr> 
                            <tr *ngIf="users?.length == 0">
                                <td colspan="5">keine Benutzer gefunden</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </form> 
        </mat-tab>
        <mat-tab label="Branchen">
            <form action="">
                <div class="flex-between-items mt-4">
                    <h3 class="title">Branchen</h3>
                    <div class="action-btn-items">
                        <!-- <div class="search-wrap">
                            <div class="search">
                               <input type="text" class="searchTerm" placeholder="nach Branche suchen">
                               <button type="submit" class="searchButton"></button>
                            </div>
                        </div> -->
                        <!-- <button class="new-item-btn" (click)="openAddSector()">
                            Add Sector
                        </button> -->
                    </div>
                </div>
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th class="column6">Bild</th>
                                <th class="column2o">Nr. Reihenfolge</th>
                                <th class="column5">Branche</th>
                                <th class="column1"></th>
                                <th class="column6"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sector of sectors">
                                <td class="column6">
                                    <img src="{{'../../../assets/images/avatars/sector_' + sector.id + '.png'}}" alt="sector image" class="small-sector-image"/>
                                </td>
                                <td class="column2o">{{sector.order_number}}</td>
                                <td class="column5">{{sector.name}}</td>
                                <td class="column1"></td>
                                <td class="column6">
                                    <button class="btn edit-btn" (click)="openEditSector(sector.id, sector.name, sector.order_number)" #tooltip="matTooltip" matTooltip="Branche bearbeiten" matTooltipPosition="left"></button>
                                </td>
                            </tr> 
                            <tr *ngIf="sectors?.length == 0">
                                <td colspan="5">keine Branchen gefunden</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </form> 
        </mat-tab>
        <mat-tab label="Funktionsgruppen"> 
            <form action="">
                <div class="flex-between-items mt-4">
                    <h3 class="title">Funktionsgruppen</h3>
                    <div class="action-btn-items">
                        <!-- <div class="search-wrap">
                            <div class="search">
                               <input type="text" class="searchTerm" placeholder="nach Funktion oder Branche suchen">
                               <button type="submit" class="searchButton"></button>
                            </div>
                        </div> -->
                        <!-- <button class="new-item-btn" (click)="openAddRole()">
                            Add Role
                        </button> -->
                    </div>
                </div>
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <!-- <th class="column6">Bild</th> -->
                                <th class="column2o">Nr. Reihenfolge</th>
                                <th class="column2">Funktionsgruppe</th>
                                <th class="column1"></th>
                                <th class="column3">Branche</th>
                                <th class="column6"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let role of roles">
                                <!-- <td class="column6">
                                    <img src="{{'../../../assets/images/avatars/sector_' + role.sector_id + '.png'}}" alt="sector image" class="small-sector-image"/>
                                </td> -->
                                <td class="column2o">{{role.order_number}}</td>
                                <td class="column32">{{role.wrapped_name}}</td>
                                <td class="column1"></td>
                                <td class="column32">{{getSector(role.sector_id)}}</td>
                                <td class="column6">
                                    <button class="btn edit-btn" (click)="openEditRole(role.id, role.name, role.wrapped_name, role.sector_id, role.order_number)" #tooltip="matTooltip" matTooltip="Funktionsgruppe bearbeiten" matTooltipPosition="left"></button>
                                </td>
                            </tr> 
                            <tr *ngIf="roles?.length == 0">
                                <td colspan="5">keine Funktionsgruppen gefunden</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </form> 
        </mat-tab>
        <mat-tab label="Funktionen"> 
            <form action="">
                <div class="flex-between-items mt-4">
                    <h3 class="title">Funktionen</h3>
                    <div class="action-btn-items">
                        <!-- <div class="search-wrap">
                            <div class="search">
                               <input type="text" class="searchTerm" placeholder="nach Funktion oder Branche suchen">
                               <button type="submit" class="searchButton"></button>
                            </div>
                        </div> -->
                        <button class="new-item-btn" (click)="openAddRole()">
                            Funktion hinzufügen
                        </button>
                    </div>
                </div>
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th class="column6">Bild</th>
                                <th class="column1">Funktionsbezeichnung</th>
                                <th class="column2"></th>
                                <th class="column3">Funktionsgruppe</th>
                                <th class="column3">Branche</th>
                                <th class="column6"></th>
                                <th class="column6"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let role of underRoles">
                                <td class="column6">
                                    <!-- <img src="{{'../../../assets/images/avatars/sector_' + getSectorId(role.role_group) + '.png'}}" alt="sector image" class="small-sector-image"/> -->
                                    <img [src]="getImageUrl(role.name, role.role_group)" (error)="onImgError($event, role.role_group)" alt="sector image" class="small-sector-image"/>
                                </td>
                                <td class="column1">{{role.name}}</td>
                                <td class="column2"></td>
                                <td class="column3">{{getUnderRole(role.role_group)}}</td>
                                <th class="column3">{{getUnderRoleSector(role.role_group)}}</th>
                                <td class="column6 col-two-icons">
                                    <button class="btn delete-btn" (click)="deleteUnderRole(role.id)" #tooltip="matTooltip" matTooltip="Funktion löschen" matTooltipPosition="left"></button>
                                </td>
                                <td class="column6">
                                    <button class="btn edit-btn" (click)="openEditUnderRole(role.id, role.name, role.role_group, getSectorId(role.role_group))" #tooltip="matTooltip" matTooltip="Funktion bearbeiten" matTooltipPosition="left"></button>
                                </td>
                            </tr> 
                            <tr *ngIf="underRoles?.length == 0">
                                <td colspan="5">keine Funktionen gefunden</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </form> 
        </mat-tab>
    </mat-tab-group>
</div>