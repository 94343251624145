import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth : AuthService, private router : Router) { 
  }

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if(this.auth.isLoggedIn()) {
      if(this.auth.hasSubscription()) {
        return true;
      } else {
        this.router.navigate(['no-subscription']);
      }
    } else {
      // Save the intended destination URL before redirecting to login
      this.auth.setReturnUrl(state.url);
      this.router.navigate(['login']);
      return false;
    }
  }
}
