import { Component, OnInit } from '@angular/core';
import { SearchService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.css']
})
export class SavedSearchesComponent implements OnInit {

  public searches = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute, private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.searchService.getSearches().subscribe(response => {
      this.searches = response.searches;
    })
  }

  gotoSearch(search) {
    this.router.navigate(['main'], {queryParams: {sector_id: search.sector_id, role_id: search.role_id, cantons: search.cantons, from: 'saved-searches'}});
  }

  deleteSearch(search) {
    this.searchService.deleteSearch(search.id).subscribe(response => {
      this.searches = response.searches;
    })
  }

}
