import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {
    }

    getUsers(orderBy = null, direction = null, search = null) {
        let param = '';
        if (orderBy) {
            param = '?order_by=' + orderBy + '&direction=' + direction;
        }
        if (search && search.length) {
            if (param.length) {
                param += '&';
            } else {
                param += '?';
            }
            param += 'search=' + search;
        }

        return this.http.get<any>(`${environment.apiUrl}/users${param}`);
    }

    getUser(id) {
        return this.http.get<any>(`${environment.apiUrl}/user/${id}`);
    }

    storeUser(data) {
        return this.http.post<any>(`${environment.apiUrl}/user`, data);
    }

    updateUser(data, id) {
        return this.http.put<any>(`${environment.apiUrl}/user/${id}`, data);
    }

    updateUserSubscription(data, id) {
        return this.http.put<any>(`${environment.apiUrl}/user-subscription/${id}`, data);
    }

    deleteUser(id) {
        return this.http.delete<any>(`${environment.apiUrl}/user/${id}`);
    }

    exportUsers() {
        return this.http.get<any>(`${environment.apiUrl}/export-users`);
    }
}
