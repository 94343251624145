<form action="">
    <div class="page-size centered-items">
        <img src="../../assets/images/pse_logo.jpg" alt="" class="image">
        <p class="title">Verifizierungs Code</p>

        <div class="flexed-items mb-3">
            <code-input 
                #codeInput
                [isCodeHidden]="false"
                [codeLength]="4"
                (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onCodeCompleted($event)">
            </code-input>
        </div>
       
        <div class="flexed-items">
            <button class="btn-el">Code erneut senden</button>
            <button class="btn-el filled" (click)="verify()">Verifizieren</button>
        </div>
    </div>
</form>
