<div class="space-between-items mb-2">
    <h2 mat-dialog-title class="mb-0">Add Sector</h2>
    <div>
        <button mat-icon-button color="#53565a" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="addSectorForm" (ngSubmit)="add()">
        <div class="inline-flex">
            <div class="input-box-wrapper full-width">
                <label class="label-item">name</label>
                <input type="text" formControlName="name" class="input-item available-width">
              </div>
        </div>
        <div class="centered">
            <button class="btn-elem mb-2">speichern</button>
        </div>
    </form> 
</mat-dialog-content>