import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ConnectionsComponent } from './connections/connections.component';
import { FavoritedTalentsComponent } from './favorited-talents/favorited-talents.component';
import { ForgotPasswordComponent } from './forgot-password-components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password-components/reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { OnePagerComponent } from './one-pager/one-pager.component';
import { RegisterComponent } from './register/register.component';
import { RegisterPromocodeComponent } from './register-promocode/register-promocode.component';
import { ReportsComponent } from './reports/reports.component';
import { NoSubscriptionRidirectComponent } from './ri-directions/no-subscription-ridirect/no-subscription-ridirect.component';
import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { AdminAuthGuard } from './shared/admin-auth.guard';
import { AuthGuard } from './shared/auth.guard';
import { VerificationCodeComponent } from './verification-code/verification-code.component';
import { VerificationPageComponent } from './verification-page/verification-page.component';
import { InfoComponent } from './info/info.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'info', component: InfoComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'registerpromo', component: RegisterPromocodeComponent },
  { path: 'verification-code', component: VerificationCodeComponent },
  { path: 'verification-code/verifying', component: VerificationPageComponent },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'one-pager', component: OnePagerComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AdminAuthGuard] },
  { path: 'admin/reports', component: ReportsComponent, canActivate: [AdminAuthGuard] },
  { path: 'favorited-talents', component: FavoritedTalentsComponent, canActivate: [AuthGuard] },
  { path: 'saved-searches', component: SavedSearchesComponent, canActivate: [AuthGuard] },
  { path: 'connections', component: ConnectionsComponent, canActivate: [AuthGuard] },
  { path: 'no-subscription', component: NoSubscriptionRidirectComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule { }
