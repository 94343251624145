import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditUserDialogComponent } from '../dialogs/edit-user-dialog/edit-user-dialog.component';
import { EditSectorDialogComponent } from '../dialogs/edit-sector-dialog/edit-sector-dialog.component';
import { EditRoleDialogComponent } from '../dialogs/edit-role-dialog/edit-role-dialog.component';
import { AddUserDialogComponent } from '../dialogs/add-user-dialog/add-user-dialog.component';
import { AddSectionDialogComponent } from '../dialogs/add-section-dialog/add-section-dialog.component';
import { AddRoleDialogComponent } from '../dialogs/add-role-dialog/add-role-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import {FormControl, FormGroup} from '@angular/forms';
import { StringDecoder } from 'string_decoder';
import { MainService } from '../_services/main.service';
import { UserService } from '../_services';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { EditUnderRoleDialogComponent } from '../dialogs/edit-under-role-dialog/edit-under-role-dialog.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';



export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AdminComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public users = [];

  public sectors = [];

  public roles = [];

  public underRoles = [];

  public sorting = '';
  public tab = 'users';
  public column = '';
  public search = '';
  public otherTalentImage = '7-' + Math.round((Math.random() * 3 + 1));
  
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private mainService: MainService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private http: HttpClient
  ) { 

    this.userService.getUsers().subscribe(response => {
      this.users = response.users;
    });

    this.mainService.getSectors().subscribe(response => {
      this.sectors = response.sectors;
    });
    
    this.mainService.getAllRoles().subscribe(response => {
      this.roles = response.roles;
    });
    
    this.mainService.getUnderRoles().subscribe(response => {
      this.underRoles = response.underRoles;
    });
  }

  ngOnInit(): void {}

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    alert("You logged out");
    this.router.navigate(['']);
  }

  openEditUser(id: number, name: string, lastName: string, companyName: string, email: string, role: string): void {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      data: {id: id, name: name, lastName: lastName, companyName: companyName, email: email, role: role}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.userService.getUsers().subscribe(response => {
        this.users = response.users;
      });
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  openEditSector(id: number, name: string, orderNumber: number): void {
    const dialogRef = this.dialog.open(EditSectorDialogComponent, {
      data: {id: id, name: name, orderNumber: orderNumber}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.mainService.getSectors().subscribe(response => {
        this.sectors = response.sectors;
      });
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  openEditRole(id: number, name: string, wrapped_name: string, sector: string, orderNumber: number): void  {
    const dialogRef = this.dialog.open(EditRoleDialogComponent, {
      data: {id: id, name: name, wrapped_name: wrapped_name, sector: sector, orderNumber: orderNumber}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.mainService.getAllRoles().subscribe(response => {
        this.roles = response.roles;
      });
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  openEditUnderRole(id: number, name: string, group: number, sector: number): void  {
    const dialogRef = this.dialog.open(EditUnderRoleDialogComponent, {
      data: {id: id, name: name, role_group: group, sector: sector}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.mainService.getUnderRoles().subscribe(response => {
        this.underRoles = response.underRoles;
      });
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  openAddUser() {
    this.dialog.open(AddUserDialogComponent);
  }
  openAddSector() {
    this.dialog.open(AddSectionDialogComponent);
  }
  openAddRole() {
    const dialogRef = this.dialog.open(AddRoleDialogComponent);
    
    dialogRef.afterClosed().subscribe(result => {
      this.mainService.getUnderRoles().subscribe(response => {
        this.underRoles = response.underRoles;
      });
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  deleteuser(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "550px",
      data: {
        title: '', 
        message: 'Sind Sie sicher, dass Sie diesen Benutzer löschen wollen?',
        confirmationOnly: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.userService.deleteUser(id).subscribe(response => {
          if(response.message == "can not delete an admin!") {
            this._snackBar.open('Kann einen Admin nicht löschen!', 'ok', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 3 * 1000,
            });
          } else {
            this.userService.getUsers().subscribe(response => {
              this.users = response.users;
              this._snackBar.open('Deleted successfully!', 'ok', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 3 * 1000,
              });
            });
          }
        })
      }
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  deleteUnderRole(id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "550px",
      data: {
        title: '', 
        message: 'Are you sure you want to delete this funktionen?',
        confirmationOnly: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.mainService.deleteUnderRole(id).subscribe(response => {
          // update table
          this.mainService.getUnderRoles().subscribe(response => {
            this.underRoles = response.underRoles;
            this._snackBar.open('Deleted successfully!', 'ok', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 3 * 1000,
            });
          });
        })
      }
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
    });
  }

  dateChange(event: any, id) {
    const date = event.target.value;
    this.userService.updateUserSubscription({subscription: date}, id).subscribe(response => {
      // update table
      this.userService.getUsers().subscribe(response => {
        this.users = response.users;
      });
    })
  }

  sort(tab, column) {
    if (this.tab == tab && this.sorting !== 'desc') {
      this.sorting = 'desc';
    } else {
      this.sorting = 'asc';
    }
    
    this.tab = tab;
    this.column = column;

    if (tab === 'users') {
      this.userService.getUsers(this.column, this.sorting, this.search).subscribe(response => {
        this.users = response.users;
      });
    }

  }

  _search() {
    if (this.tab === 'users') {
      this.userService.getUsers(this.column, this.sorting, this.search).subscribe(response => {
        this.users = response.users;
      });
    }
  }

  uploadImage(id) {
    console.log("Upload image for sector number: " + id);
  }

  getSector(id):string {
    for(let i = 0; i < this.sectors.length; i++) {
      if(this.sectors[i].id == id) {
        return "" + this.sectors[i].name;
      }
    }
  }

  getUnderRole(roleId):string {
    for(let i = 0; i < this.roles.length; i++) {
      if(this.roles[i].id == roleId) {
        return "" + this.roles[i].name;
      }
    }
  }

  getUnderRoleSector(roleId):string {
    for(let i = 0; i < this.roles.length; i++) {
      if(this.roles[i].id == roleId) {
        for(let j = 0; j < this.sectors.length; j++) {
          if(this.sectors[j].id == this.roles[i].sector_id) {
            return "" + this.sectors[j].name;
          }
        }
      }
    }
  }

  getSectorId(roleId):number {
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id === roleId) {
        return this.roles[i].sector_id;
      }
    }
  }


  getImageUrl(role_name, roleId) {
    const name = role_name.replaceAll("ü", "u").replaceAll("ä", 'a').replaceAll("ö", 'o').replaceAll("Ü", "U").replaceAll("Ä", 'A').replaceAll("Ö", 'O').replaceAll("é", 'e').replaceAll("ß", "B").replaceAll(" / ", "");
    let sectorId = this.getSectorId(roleId);
    
    return '../../../assets/images/avatars/' + sectorId + '/' + roleId + '/' + encodeURI(name)  + '.png';
  }

  onImgError(e, roleId) {
    const sectorId = this.getSectorId(roleId);
    e.target.src = '../../../assets/images/avatars/sector_' + sectorId + '.png';
  }

  files: File[] = [];

  onSelect(event) {
    this.files = event.addedFiles;
    // this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  exportXls() {
    // console.log("Exporting...");
    // this.userService.exportUsers().subscribe(response => {
    //   console.log(response);
    // })

    const url = `${environment.apiUrl}/export-users`;
    this.http.get(url, { responseType: 'blob' }).subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'PermServEasy Benutzer.xlsx';
        link.click();
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  }
}