import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class SearchService {
    constructor(private http: HttpClient) {
    }

    getSearches() {
        return this.http.get<any>(`${environment.apiUrl}/searches`);
    }

    getSearch(id) {
        return this.http.get<any>(`${environment.apiUrl}/search/${id}`);
    }

    storeSearch(data) {
        return this.http.post<any>(`${environment.apiUrl}/search`, data);
    }

    deleteSearch(id) {
        return this.http.delete<any>(`${environment.apiUrl}/search/${id}`);
    }
}
