import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService, MainService } from 'src/app/_services';
import { AppConfig } from "../../helpers/app.config";
@Component({
  selector: 'app-edit-sector-dialog',
  templateUrl: './edit-sector-dialog.component.html',
  styleUrls: ['./edit-sector-dialog.component.css']
})
export class EditSectorDialogComponent implements OnInit {
  id = 0;
  name = '';
  orderNumber = 0;
  editSectorForm: FormGroup;
  public isInValid !: boolean; 
  isImageSelected = false;
  selectedImage = null;
  files: File[] = [];

  // public uploadPictureConfig = {
  //   url: AppConfig.storeEmployeePictureUrl,
  //   params: { employee_id: null },
  //   autoDiscover: false,
  //   acceptedFiles: "image/*",
  //   headers: {},
  // };

  public userImageThumbnailRootPath = null;

  @Output() submitClicked = new EventEmitter<any>();
  constructor(
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<EditSectorDialogComponent>,
    private mainService: MainService,
    // private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
    this.name = this.data.name;
    this.orderNumber = this.data.orderNumber;
    this.editSectorForm = new FormGroup({
      'name' : new FormControl(this.name, Validators.required),
      'order_number' : new FormControl(this.orderNumber, Validators.required),
    })

    const apiToken = '';
    // this.uploadPictureConfig.headers = { Authorization: "Bearer " + apiToken };
  }

  save() {
    this.mainService.updateSector({name: this.editSectorForm.value.name, order_number: this.editSectorForm.value.order_number}, this.id).subscribe(response => {
      console.log(response);
    });
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSelect(event) {
    this.files = event.addedFiles;
    // this.files.push(...event.addedFiles);
    console.log(event);
    console.log(event.addedFiles[0].name);
    console.log(this.files);
    this.fileService.uploadFile({file: this.files[0], type: 'sector', id: this.id}).subscribe(response => {
      console.log(response);
    })
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  // upload options prej jobrocks

  onUploadError(event) {
    event[0]._removeLink.click();
  }

  uploadFileType(type, id, data) {
    const formData = data[2];
    formData.append("type", type);
    formData.append("id", id);
    this.fileService.uploadFile(formData).subscribe(response => {
      console.log(response);
    })
  }

  onUploadSuccess(event) {
    if (event[1].status === "error") {
      console.log("error");
    } else {
      this.files[event[1].type] = event[1].file_path;
    }
    event[0]._removeLink.click();
  }

  deleteImage() {
    console.log("delte clicked!");
  }

}
