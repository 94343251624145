import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FavoriteService, TalentService } from '../_services';

@Component({
  selector: 'app-favorited-talents',
  templateUrl: './favorited-talents.component.html',
  styleUrls: ['./favorited-talents.component.css']
})
export class FavoritedTalentsComponent implements OnInit {

  public talents = [];

  constructor(
    private router: Router,
    private favoriteService: FavoriteService,
    private talentService: TalentService,
  ) { }

  ngOnInit(): void {
    this.talentService.getFavoriteTalents().subscribe(response => {
      this.talents = response.talents.map(talent => {
        return {...talent, isFav: true};
      });
    });
  }

  addToFavorites(talent) {
    this.favoriteService.storeFavorite({talent_id: talent.manum}).subscribe(response => {
      talent.isFav = true;
    });
  }

  removeFromFavorites(talent) {
    this.favoriteService.removeFavorite(talent.manum).subscribe(response => {
      talent.isFav = false;
    });
  }
  
  getImageUrl(talent) {
    const talentPosition = talent.current_position.replaceAll("ü", "u").replaceAll("ä", 'a').replaceAll("ö", 'o').replaceAll("Ü", "U").replaceAll("Ä", 'A').replaceAll("Ö", 'O').replaceAll("é", 'e').replaceAll("ß", "B").replaceAll(" / ", "");
    return '../../../assets/images/avatars/' + talent.sector_id + '/' + talent.role_id + '/' + encodeURI(talentPosition)  + '.png';
  }

  onImgError(e, sector_id) {
    e.target.src = '../../../assets/images/avatars/sector_' + sector_id + '.png';
  }

}
