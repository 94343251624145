import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class LikeService {
    constructor(private http: HttpClient) {
    }

    getLikes(user_id, talent_id) {
        let getParams = '';

        if (user_id) {
            getParams += `user_id=${user_id}`
        }
        if (talent_id) {
            getParams += `&talent_id=${talent_id}`
        }
        return this.http.get<any>(`${environment.apiUrl}/likes?${getParams}`)
    }

    getLike(id) {
        return this.http.get<any>(`${environment.apiUrl}/like/${id}`);
    }

    storeLike(data) {
        return this.http.post<any>(`${environment.apiUrl}/like`, data);
    }

    deleteLike(id) {
        return this.http.delete<any>(`${environment.apiUrl}/like/${id}`);
    }



    
    
   
    

    


    // '/role/' + id; == `/role/${id}`;


    // krejt sectors edhe roles edhe talent funksionet e backendit me i ba ktu me logjiken si me lart



    // get example
    // getRole(id) {
    //     return this.http.get<any>(`${environment.apiUrl}/role/${id}`);
    // }

    // post example
    // login(data) {
    //     return this.http.post<any>(`${environment.apiUrl}/auth/login`, data);
    // }

    // put example
    // login(data, id) {
    //     return this.http.put<any>(`${environment.apiUrl}/auth/login/${id}`, data);
    // }

    // delete example
    // login(id) {
    //     return this.http.delete<any>(`${environment.apiUrl}/auth/login/${id}`);
    // }
}

