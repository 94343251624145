import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.css']
})
export class EditUserDialogComponent implements OnInit {
  name = '';
  lastName = '';
  companyName = '';
  email = '';
  id = 0;
  role = '';
  editUserForm: FormGroup;
  public isInValid !: boolean; 

  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
    this.name = this.data.name;
    this.lastName = this.data.lastName;
    this.companyName = this.data.companyName;
    this.email = this.data.email;
    this.role = this.data.role;

    this.editUserForm = new FormGroup({
      'name' : new FormControl(this.name, Validators.required),
      'lastName' : new FormControl(this.lastName, Validators.required),
      'companyName' : new FormControl(this.companyName, Validators.required),
      'email' : new FormControl(this.email, Validators.required),
      'role' : new FormControl(this.role, Validators.required),
    })
  }

  save() {
    this.userService.updateUser({first_name: this.editUserForm.value.name, last_name: this.editUserForm.value.lastName, company_name: this.editUserForm.value.companyName, email: this.editUserForm.value.email, role: this.editUserForm.value.role}, this.id).subscribe(response => {
      // console.log(response);
    })
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
}
