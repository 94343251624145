import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private auth : AuthService, private router : Router) { 
  }

  canActivate(){
    if(this.auth.isAdminLoggedIn()) {
      return true;
    }
    alert("You are not logged in!");
    this.router.navigate(['login']);
    return false;
  }
}
