<div class="e-u-r-width">
    <div class="space-between-items mb-2">
        <h2 mat-dialog-title class="mb-0">Funktion bearbeiten</h2>
        <div>
            <button mat-icon-button color="#53565a" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content class="mat-typography">
        <form [formGroup]="editUnderRoleForm" (ngSubmit)="save()">
            <div>
                <label class="label-item">Bild <span class="required-label">*</span></label>
                <div class="inline-flex">
                    <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="image-upload" *ngIf="files.length == 0">
                        <ngx-dropzone-label>
                            <img src="../../../assets/images/Icons/upload-gray.svg" alt="upload" style="width: 15px; height: 15px;">
                        </ngx-dropzone-label>
                    </ngx-dropzone>
                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" class="image-upload-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)"></ngx-dropzone-image-preview>
                </div>
                <div class="required-text mb-2" *ngIf="files.length === 0">
                    Bild ist erforderlich
                </div>
            </div>
            <div class="inline-flex">
                <div class="input-box-wrapper full-width">
                    <label class="label-item">Funktionsbezeichnung <span class="required-label">*</span></label>
                    <input type="text" formControlName="name" class="input-item available-width" [class.required-input]="(editUnderRoleForm.get('name').touched && editUnderRoleForm.get('name').invalid)">
                    <div class="required-text" *ngIf="(editUnderRoleForm.get('name').touched && editUnderRoleForm.get('name').invalid)">
                        Funktionsbezeichnung ist erforderlich
                    </div>
                </div>
            </div>
            <div class="inline-flex mb-2">
                <div class="full-width">
                    <label class="label-item">Branche <span class="required-label">*</span></label>
                    <mat-select class="custom-select available-width" formControlName="sector_group">
                        <mat-option *ngFor="let sector of sectors" value="{{sector.id}}" (click)="selectSector(sector)">
                            {{sector.name}}
                        </mat-option>
                    </mat-select>
                    <div class="required-text" *ngIf="(editUnderRoleForm.get('sector_group').touched && editUnderRoleForm.get('sector_group').invalid)">
                        Branche ist erforderlich
                    </div>
                </div>
            </div>
            <div class="inline-flex mb-2">
                <div class="full-width">
                    <label class="label-item">Funktionsgruppe <span class="required-label">*</span></label>
                    <mat-select class="custom-select available-width" formControlName="role_group">
                        <mat-option *ngFor="let group of role_groups" value="{{group.id}}">
                            {{group.name}}
                        </mat-option>
                    </mat-select>
                    <div class="required-text" *ngIf="(editUnderRoleForm.get('role_group').touched && editUnderRoleForm.get('role_group').invalid)">
                        Funktionsgruppe ist erforderlich
                    </div>
                </div>
            </div>
            <div class="centered">
                <button class="btn-elem mb-2" [disabled]="!editUnderRoleForm.valid || files.length === 0">speichern</button>
            </div>
        </form> 
    </mat-dialog-content>
</div>