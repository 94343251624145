import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class FavoriteService {
    constructor(private http: HttpClient) {
    }

    getFavorites() {
        return this.http.get<any>(`${environment.apiUrl}/favorites`)
    }

    getFavorite(id) {
        return this.http.get<any>(`${environment.apiUrl}/favorite/${id}`);
    }

    storeFavorite(data) {
        return this.http.post<any>(`${environment.apiUrl}/favorite`, data);
    }

    removeFavorite(id) {
        return this.http.delete<any>(`${environment.apiUrl}/favorite/${id}`);
    }



    
    
   
    

    


    // '/role/' + id; == `/role/${id}`;


    // krejt sectors edhe roles edhe talent funksionet e backendit me i ba ktu me logjiken si me lart



    // get example
    // getRole(id) {
    //     return this.http.get<any>(`${environment.apiUrl}/role/${id}`);
    // }

    // post example
    // login(data) {
    //     return this.http.post<any>(`${environment.apiUrl}/auth/login`, data);
    // }

    // put example
    // login(data, id) {
    //     return this.http.put<any>(`${environment.apiUrl}/auth/login/${id}`, data);
    // }

    // delete example
    // login(id) {
    //     return this.http.delete<any>(`${environment.apiUrl}/auth/login/${id}`);
    // }
}

