<div class="header">
    <div style="width: 210px;">
    </div>
    <a [routerLink]="user ? ['/main'] : ['/login']">
      <img src="../../../assets/images/pse_logo.jpg" alt="PSE Logo" class="pse-header-logo"/>
    </a>
    <div class="flexed-items" style="width: 210px;">
      <a [routerLink]="user ? ['/saved-searches'] : ['/login']">
        <img src="../../../assets/images/Icons/bookmark-grey.png" alt="bookmark" class="icon-btn" matTooltip="Gespeicherte Suchen">
      </a>
      <a [routerLink]="user ? ['/favorited-talents'] : ['/login']">
        <img src="../../../assets/images/Icons/favourites-grey.png" alt="favorites" class="icon-btn" matTooltip="Meine Favoriten">
      </a>
      <a [routerLink]="user ? ['/connections'] : ['/login']">
        <img src="../../../assets/images/Icons/hand-shake-hover.png" alt="connections" class="icon-btn handshake" matTooltip="Angefragte Talente">
      </a>
      <a [routerLink]="user ? ['/main'] : ['/login']">
        <img src="../../../assets/images/Icons/search-talents.svg" alt="Zur Applikation" class="icon-btn" matTooltip="Zur Applikation">
      </a>
      <div (click)="logout()" *ngIf="user">
            <img src="../../../assets/images/Icons/log-out.png" alt="Log out" class="icon-btn" matTooltip="Ausloggen">
      </div>
      <a *ngIf="!user" [routerLink]="['/login']">
        <img src="../../assets/images/Icons/log-in.png" class="icon-btn" alt="Login" matTooltip="Login" style=""/>
      </a>
    </div>
  </div>

<div class="p-10">
    <div class="centered mb-5">
        <!-- <h1 class="title mb-10">PermServEasy ermöglicht während 365/24 einen intuitiven und</h1>
        <h1 class="title">effizienten Zugang zu einem branchenspezifischen Talentpool.</h1> -->
        <h1 class="title supertitle">PermServEasy ermöglicht während 365/24 einen intuitiven und effizienten Zugang zu einem branchenspezifischen Talentpool.</h1>
        <img src="../../assets/images/PermServ Relauch Picture.png" class="pse-img"/>
        <!-- <h1 class="title mb-10">Mit nur wenigen Klicks unterstützt Sie PermServEasy</h1>
        <h1 class="title">bei der Suche nach passenden Mitarbeitenden.</h1> -->
        <h1 class="title supertitle2">Mit nur wenigen Klicks unterstützt Sie PermServEasy bei der Suche nach passenden Mitarbeitenden.</h1>
    </div>
    <div class="mb-5">
        <h1 class="title">Ihr Nutzen & Ihre Vorteile</h1>
        <ul class="list">
            <li class="list-item">Rund um die Uhr nutzbarer und kostenloser Service</li>
            <li class="list-item">Exklusiver Zugang für Kunden von PermServ</li>
            <li class="list-item">Intuitive und leicht verständliche Navigation</li>
            <li class="list-item">Unsere Talente: Qualifizierte Kandidatinnen und Kandidaten, welche durch PermServ geprüft sind</li>
            <li class="list-item">
                <p class="mb-10">Vertraulich eine Marktsicht gewinnen über vorhandene Talente anhand folgender Suchkriterien:</p>
                
                <ul class="list-dash">
                    <li class="list-dash-item">Branche</li>
                    <li class="list-dash-item">Funktion</li>
                    <li class="list-dash-item">Region</li>
                </ul>
            </li>
            <li class="list-item">
                <p class="mb-10">Informationen über die Talente anhand anonymisierter Profile:</p>
                <ul class="list-dash">
                    <li class="list-dash-item">Funktion, Alter, Wohnort</li>
                    <li class="list-dash-item">Verfügbarkeit, Jahressalär, Pensum</li>
                    <li class="list-dash-item">Qualifikationen, Erfahrungen, Kompetenzen & Persönlichkeit</li>
                </ul>
            </li>
            <li class="list-item">Bei Interesse an Talenten: Kontaktaufnahme mit dem Kandidaten via PermServEasy</li>
            <li class="list-item">Speichern von getätigten Suchanfragen und Push-Mails bei neuen Talenten mit entsprechenden Suchkriterien</li>
        </ul>
    </div>

    <div class="mb-5">
        <h1 class="title mb-16">Navigation</h1>
        <div class="custom-image-line">
            <img src="../../assets/images/Icons/bookmark-grey.png" class="icon-img" />
            <span class="bold">Gespeicherte Suche</span>
            <p class="text">Sämtliche gespeicherten Suchanfragen auf einen Blick</p>
        </div>
        <div class="custom-image-line">
            <img src="../../assets/images/Icons/favourites-grey.png" class="icon-img" />
            <span class="bold">Meine Favoriten</span>
            <p class="text">Schnellzugriff auf Ihre favorisierten Profile</p>
        </div>
        <div class="custom-image-line">
            <img src="../../assets/images/Icons/hand-shake-hover.png" class="icon-img" />
            <span class="bold">Angefragte Talente</span>
            <p class="text">Direktzugriff auf diejenige Talente, für die Sie sich interessieren und wir für Sie kontaktieren</p>
        </div>
        <div class="custom-image-line">
            <img src="../../assets/images/Icons/info.png" class="icon-img" />
            <span class="bold">Informationen</span>
            <p class="text">Vision von PermServEasy, Vorteile und Nutzen der Plattform und Kontakt</p>
        </div>
        <div class="custom-image-line">
            <img src="../../assets/images/Icons/log-out.png" class="icon-img"/>
            <span class="bold">Ausloggen</span>
            <p class="text">Abmeldung</p>
        </div>
    </div>

    <div class="mb-5">
        <h1 class="title mb-16">Registrierung & Anmeldung</h1>
        <p class="bold">Registrierung</p>
        <ul class="list">
            <li class="list-item">
                <a [routerLink]="['/register']" class="green">Registrieren</a> Sie sich als Kunde von PermServEasy mit Ihrer Mailadresse auf der Plattform und 
                einem freigewählten Passwort.
            </li>
            <li class="list-item">
                Ihr Account wird automatisch freigeschaltet und Sie erhalten uneingeschränkten Zugang zu sämtlichen Profilen unserer Talente.
            </li>
        </ul>
        <p class="bold">Anmeldung</p>
        <ul class="list">
            <li class="list-item">
                Haben Sie bereits ein <a [routerLink]="['/login']"  class="green">Login</a>? Dann nichts wie los!
            </li>
        </ul>
    </div>

    <div class="centered mb-5">
        <h1 class="title mb-16">Haben Sie Fragen oder ein Anliegen?</h1>
        <p  class="text supertext mb-0">Ich, Simone Schwendimann, PermServEasy Manager, stehe Ihnen unter 044 552 71 18 oder <a target="_blank" href="mailto:info@permserveasy.ch" class="green">info@permserveasy.ch</a> gerne zur Verfügung. Selbstverständlich können Sie auch jederzeit auf Ihre gewohnte PermServ-Ansprechperson zugehen. </p>
        <img src="../../assets/images/Simone Schwendimann.jpg" alt="Simone Schwendimann" class="img-portrait"/>
        <p  class="text">Wir nehmen uns gerne Zeit für Ihr Anliegen!</p>
    </div>
</div>
