import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  submitted = false;
  validSubmit = false;
  linkSentSuccessfully = false;
  isPasswordResent = false;
  
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}




  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      'email' : new FormControl(null, Validators.required)
    })
  }

  changeEmail() {
    this.submitted = false;
    this.validSubmit = false;
    this.linkSentSuccessfully = false;
    this.isPasswordResent = false;
  }

  resetPassword() {
    if(this.forgotPasswordForm.valid) {
      this.validSubmit = true;
      this.linkSentSuccessfully = true;
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
        response => {
          console.log('ok')
        },
        error => {
          console.log('not ok');
        }
      );
    } else {
      this.submitted = true;
    }
  }

  resendPassword() {
    this.isPasswordResent = true;
  }

}
