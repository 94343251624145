<app-header></app-header>
<div class="body">
    <!-- <h3 class="title mv-4">Favorited talents.</h3> -->
    <div class="mt-2"></div>
    <form action="">
        <div class="flex-between-items mv-4">
            <h3 class="title mb-0">Gespeicherte Suchen</h3>
        </div>
        <div class="table100">
            <table>
                <thead>
                    <tr class="table100-head">
                        <th class="column1">Branche</th>
                        <th class="column2">Funktion</th>
                        <th class="column3">Region</th>
                        <th class="column6"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let search of searches">
                        <td class="column1" (click)="gotoSearch(search)">{{search?.sector}}</td>
                        <td class="column2 role-name" (click)="gotoSearch(search)">{{search?.role}}</td>
                        <td class="column3" (click)="gotoSearch(search)">{{search?.cantons}}</td>
                        <td class="column6">
                            <button class="btn search-btn" matTooltip="Suche ausführen" matTooltipPosition="left" (click)="gotoSearch(search)"></button>
                            <button class="btn delete-btn" matTooltip="Suche löschen" matTooltipPosition="left" (click)="deleteSearch(search)"></button>
                        </td>
                    </tr> 
                    <tr *ngIf="searches.length == 0">
                        <td colspan="5">Keine Gespeicherte Suchen gefunden!</td>
                    </tr> 
                </tbody>
            </table>
        </div>
    </form> 
</div>