import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_services';

@Component({
  selector: 'app-register-promocode',
  templateUrl: './register-promocode.component.html',
  styleUrls: ['./register-promocode.component.css']
})
export class RegisterPromocodeComponent implements OnInit {

  public code = '';
  public promocode = '';
  public submitted = false;
  public isVerifying = false;
  public isRegistering = false;
  userForm: FormGroup;
  public showPassword = false;
  public showConfirmPassword = false;
  public activePage = "register";
  notSamePasswords = false;
  emailExists = false;
  promocodeInvalid = false;
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(resp => {
      if(resp.ref) {
        this.promocode = resp.ref;
      }
    });

    this.userForm = new FormGroup({
      'first_name' : new FormControl(null, Validators.required),
      'last_name' : new FormControl(null, Validators.required),
      'company_name' : new FormControl(null, Validators.required),
      'email' : new FormControl(null, Validators.required),
      'password' : new FormControl(null, [Validators.required,Validators.minLength(6)]),
      'password_confirmation' : new FormControl(null, Validators.required),
      'promocode' : new FormControl(this.promocode, Validators.nullValidator),
      // 'subscription_plan' : new FormControl(null, Validators.required)
    });
  }

  register() {
    this.submitted = true;
    if(this.userForm.valid && !this.promocodeInvalid) {
      if(this.userForm.value.password !== this.userForm.value.password_confirmation) {
        this.notSamePasswords = true;
      } else {
        this.notSamePasswords = false;
        this.isRegistering = true;
        this.authService.sendVerification(this.userForm.value).subscribe(response => {
          if (response.exists == false) {
            this.activePage = "verification-code";
          } else {
            this.emailExists = true;
            this.isRegistering = false;
          }
        });
      }
    }
  }

  togglePasswordVisibility(type) {
    if(type == 'password') {
      this.showPassword = !this.showPassword;
    } else {
      this.showConfirmPassword = !this.showConfirmPassword;
    }  
  }

  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.code = code;
    // this.activePage = "verifying";
    this.verify();

  }

  verify() {
    // this.activePage = "verifying";
    this.isVerifying = true;
    this.authService.register({...this.userForm.value, code: this.code, promocode: this.userForm.value.promocode}).subscribe(response => {
      // console.log(response);
      if (response) {
        if(this.userForm.value.promocode === 'welcome_permserveasy') {
          localStorage.setItem('token', response.data.token);
          const user = response.data.user;
          localStorage.setItem('user', JSON.stringify(user));
          if (user.role === 'admin') {
            this.router.navigate(['admin']);
          } else {
            this.router.navigate(['main']);
          }
          return;
        } else {
          this.activePage = "verifying";
          this.isVerifying = false;
        }
      }
    });
  }

  resend() {
    this.authService.sendVerification(this.userForm.value).subscribe(response => {
      if (response) {
        this.activePage = "verification-code";
      }
    });
  }

  checkPromocode() {
    if(this.userForm.value.promocode == '' || this.userForm.value.promocode == 'welcome_permserveasy'){
      this.promocodeInvalid = false;
    } else {
      this.promocodeInvalid = true;
    }
  }
}