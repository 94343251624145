import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, FavoriteService, SearchService, TalentService } from '../_services';
import { MainService } from '../_services/main.service';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class MainComponent implements OnInit {

  public loading = false;
  public showTalents = false;
  public isSectorSelected = false;

  public favorites = [];

  public sectors = [];
  public roles = [];
  public talents = [];
  public newTalents = [];

  public isHumanResourcesClicked = false;
  public isHotellerieClicked = false;

  public tCount = 0;
  public regionCount = 0;
  public hoveredRegion = null;
  public sortedTalents = [];

  public sectorId = null;
  public roleId = null;

  public isSaved;

  public selectedRegions = [];
  subscription: any;
  ClientRectangle: any;
  children: any;

  constructor(
    private router: Router, private talentService: TalentService, 
    private mainService: MainService, private authService: AuthService,
    private route: ActivatedRoute, private searchService: SearchService, private favoriteService: FavoriteService,
  ) {}

  ngOnInit() {
    this.favoriteService.getFavorites().subscribe(response => {
      this.favorites = response.favorites;
    }, err => {
      if (err.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.router.navigate(['']);
      }
    });
    
    this.route.queryParams.subscribe(resp => {
      let data = {};
      if (resp.sector_id && resp.role_id && resp.cantons) {

        this.sectorId = resp.sector_id;
        this.roleId = resp.role_id;
        
        if (typeof resp.cantons == 'string') {
          this.selectedRegions = resp.cantons.split(",");
        } else {
          this.selectedRegions = resp.cantons;
        }
        data = {sector_id: this.sectorId, role_id: this.roleId, cantons: this.selectedRegions};
      } 
      else if (resp.sector_id && resp.role_id) {
        this.sectorId = resp.sector_id;
        this.roleId = resp.role_id;
        data = {sector_id: this.sectorId, role_id: this.roleId};
      } 
      else if(resp.sector_id) {
        this.sectorId = resp.sector_id;
        data = {sector_id: this.sectorId};
      }
      this._main(data);
      if(resp.from == 'saved-searches') {
        this.scroll();
      }
    });

    console.log(this.sectorId)
    if(this.sectorId == 4 && this.roleId == 60) {
      this.isHumanResourcesClicked = false;
      this.isHotellerieClicked = true;
    } else if(this.sectorId == 5) {
      this.isHumanResourcesClicked = true;
      this.isHotellerieClicked = false;
    } else {
      this.isHumanResourcesClicked = false;
      this.isHotellerieClicked = false;
    }
  }

  countAllTalents() {
    this.tCount = 0;
    this.talentService.talentsCountAll().subscribe(response => {
      this.tCount += response.total;
    });
  }

  logout() {
    this.authService.logout().subscribe(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.router.navigate(['']);
    })
  }

  talentsCount() {
    this.talentService.talentsCount(this.sectorId, this.roleId, this.selectRegion).subscribe(response =>{
    })
  }
  
  sectorClicked(sectorId) {
    if(sectorId == 5) {
      this.isHumanResourcesClicked = true;
      this.isHotellerieClicked = false;
    } else {
      this.isHumanResourcesClicked = false;
      this.isHotellerieClicked = false;
    }

    this.talentService.saveActivity({
      event: 'click',
      type: 'sector',
      target: sectorId
    }).subscribe();
    this.sectorId = sectorId;
    this.router.navigate(['/main'], { queryParams: { sector_id: this.sectorId } });
  }

  roleClicked(roleId) {
    if(this.sectorId == 4 && roleId == 60) {
      this.isHotellerieClicked = true;
    } else if (this.sectorId == 5) {
      this.isHumanResourcesClicked = true;
      this.isHotellerieClicked = false;
    } else {
      this.isHumanResourcesClicked = false;
      this.isHotellerieClicked = false;
    }
    this.talentService.saveActivity({
      event: 'click',
      type: 'role',
      target: roleId
    }).subscribe();
    this.roleId = roleId;
    this.router.navigate(['/main'], { queryParams: { sector_id: this.sectorId, role_id: this.roleId } });
  }

  selectRegion(region) {
    let ri = this.selectedRegions.indexOf(region);
    
    if (ri === -1) {
      this.selectedRegions.push(region);
      this.talentService.saveActivity({
        event: 'click',
        type: 'canton',
        target: region
      }).subscribe();
    } else {
      this.selectedRegions.splice(ri, 1);
    }
    
    this.router.navigate(['/main'], { queryParams: { sector_id: this.sectorId, role_id: this.roleId, cantons: this.selectedRegions.join(',') } });
    // this.showTalents = false;
  }

  newSearch() {
    this.talents = [];
    this.roles = [];
    this.selectedRegions = [];
    this.sectorId = null;
    this.roleId = null;
    this.showTalents = false;
    this.tCount = 0;
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.countAllTalents();
  }

  saveSearch() {
    this.isSaved = !this.isSaved;
    this.searchService.storeSearch({sector_id: this.sectorId, role_id: this.roleId, cantons: this.selectedRegions, saving: this.isSaved}).subscribe(response => {
      if(response.exists == false) {
        this.isSaved = false;
      } else {
        this.isSaved = true;
      }
    });
  }

  addToFavorites(talent) {
    this.favoriteService.storeFavorite({talent_id: talent.manum}).subscribe(response => {
      talent.isFav = true;
    });
  }
  
  removeFromFavorites(talent) {
    this.favoriteService.removeFavorite(talent.manum).subscribe(response => {
      talent.isFav = false;
    });
  }

  scroll() {
    document.getElementById("target").scrollIntoView({behavior: 'smooth', block: "start", inline: "start"});
    setTimeout(() => {
      window.scrollBy(0,-10)
      }, 500);
  }

  getImageUrl(talent) {
    const talentPosition = talent.current_position.replaceAll("ü", "u").replaceAll("ä", 'a').replaceAll("ö", 'o').replaceAll("Ü", "U").replaceAll("Ä", 'A').replaceAll("Ö", 'O').replaceAll("é", 'e').replaceAll("ß", "B").replaceAll(" / ", "");
    return '../../../assets/images/avatars/' + talent.role.sector_id + '/' + talent.role.id + '/' + encodeURI(talentPosition)  + '.png';
  }

  onImgError(e, sector_id) {
    e.target.src = '../../../assets/images/avatars/sector_' + sector_id + '.png';
  }

  regionCounter(reg) {
    document.addEventListener('mousemove', (ev) => {
      var x = ev.pageX;
      var y = ev.pageY;
      if(this.hoveredRegion) {
        document.getElementById('tooltipCustomContent').classList.add("tooltipCustomContentShown");
        document.getElementById('tooltipCustomContent').style.top = (y + 20) + 'px';
        document.getElementById('tooltipCustomContent').style.left = (x + 20) + 'px';
      }
    });
    this.regionCount = this.sortedTalents[reg+''] ? this.sortedTalents[reg+''] : 0;
    this.hoveredRegion = reg;
  }
  
  _main(data) {
    if (!data.cantons && data.role_id) {
      this.selectedRegions = [];
      this.showTalents = false;
      const mediaQuery = window.matchMedia('(max-width: 800px)')
      if(mediaQuery.matches) {
        document.getElementById("region").scrollIntoView({behavior: 'smooth', block: "start", inline: "start"});
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    } else  if (!data.cantons && data.sector_id) {
      this.roleId = null;
      this.selectedRegions = [];
      this.showTalents = false;
      const mediaQuery = window.matchMedia('(max-width: 800px)')
      if (mediaQuery.matches) {
        document.getElementById("role").scrollIntoView({behavior: 'smooth', block: "start", inline: "start"});
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }

    this.loading = true;
    this.talentService.main(data).subscribe(response => {
      this.sectors = response.sectors;
      this.roles = response.roles;
      this.tCount = response.talents.length;
      this.sortedTalents = response.sorted_talents;
      
      if (data.cantons) {
        this.talents = response.talents.map(talent => {
          return {...talent, isFav: this.favorites.indexOf(talent.id) !== -1};
        });
        if(response.searchExists) {
          this.isSaved = true;
        } else {
          this.isSaved = false;
        }
        this.newTalents = response.new_talents;
        this.showTalents = true;
        this.scroll();
      }
      this.loading = false;
    });
  }

  navigateToOnePager(talent: any) {
    const queryParams = {
      talent_id: talent.manum,
      from: 'main',
      cantons: this.selectedRegions.join(','),
    };

    // Open the link in a new tab
    const onePagerUrl = this.router.serializeUrl(this.router.createUrlTree(['/one-pager'], { queryParams }));
    window.open(onePagerUrl, '_blank');

    // Conditionally reload the current page if talent is new
    if (this.newTalents.indexOf(talent.id) !== -1) {
        window.location.reload();
    }
  }
}
