<div class="space-between-items mb-2">
    <h2 mat-dialog-title class="mb-0">Benutzer bearbeiten</h2>
    <div>
        <button mat-icon-button color="#53565a" mat-dialog-close (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="editUserForm" (ngSubmit)="save()">
        <div class="inline-flex">
            <div class="input-box-wrapper">
                <label class="label-item">Vorname <span class="required-label">*</span></label>
                <input type="text" formControlName="name" class="input-item" [class.required-input]="(editUserForm.get('name').touched && editUserForm.get('name').invalid)">
                <div class="required-text" *ngIf="(editUserForm.get('name').touched && editUserForm.get('name').invalid)">
                    Vorname ist erforderlich
                </div>
              </div>
              <div class="input-box-wrapper">
                <label class="label-item">Nachname<span class="required-label">*</span></label>
                <input type="text" formControlName="lastName" class="input-item" [class.required-input]="(editUserForm.get('lastName').touched && editUserForm.get('lastName').invalid)">
                <div class="required-text" *ngIf="(editUserForm.get('lastName').touched && editUserForm.get('lastName').invalid)">
                    Nachname ist erforderlich
                </div>
              </div>
        </div>
        <div class="inline-flex">
              <div class="input-box-wrapper">
                <label class="label-item">Firmenname <span class="required-label">*</span></label>
                <input type="text" formControlName="companyName" class="input-item" [class.required-input]="(editUserForm.get('companyName').touched && editUserForm.get('companyName').invalid)">
                <div class="required-text" *ngIf="(editUserForm.get('companyName').touched && editUserForm.get('companyName').invalid)">
                    Firmenname ist erforderlich
                </div>
              </div>
              <div class="input-box-wrapper full-width">
                <label class="label-item">Rolle <span class="required-label">*</span></label>
                <mat-select class="custom-select available-width" formControlName="role">
                    <mat-option value="admin">Admin</mat-option>
                    <mat-option value="company">Kunde</mat-option>
                </mat-select>
              </div>
        </div>
        <div class="inline-flex">
            <div class="input-box-wrapper full-width">
                <label class="label-item">E-Mail <span class="required-label">*</span></label>
                <input type="text" formControlName="email" class="input-item available-width" [class.required-input]="(editUserForm.get('email').touched && editUserForm.get('email').invalid)">
                <div class="required-text" *ngIf="(editUserForm.get('email').touched && editUserForm.get('email').invalid)">
                    E-Mail ist erforderlich
                </div>
            </div>
        </div>
        <div class="centered">
            <button class="btn-elem mb-2" [disabled]="!editUserForm.valid">speichern</button>
        </div>
    </form> 
</mat-dialog-content>