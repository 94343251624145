import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ReportsService } from '../_services/reports.service';
import { Subject, of } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ReportsComponent implements OnInit {
  private destroy$ = new Subject<void>();

  loading = false;
  sectorChart = null;
  financeChart = null;
  hotellerieAndGastronomieChart = null;
  humanResourcesChart = null;
  industrialAndTechnichalChart = null;
  realEstatechart = null;
  regionChart = null;
  talentsOPChart = null;
  averageDurationChart = null;
  averageDurationChartNames = [];
  averageDurationDateRangeChart = null;
  averageDurationDateRangeChartNames = [];
  mostSearchedRolesChart = null;
  mostSearchedRolesChartNames = [];
  usedDeviceChart = null;
  whichCostumerWhichQuery = [];
  whichTalentsWithOnePager = [];
  // averageDuration = [];
  candidateAlertQuery = [];
  candidateDays = [];
  dayTimeActive = [];
  sectorPeriod = 'year';
  regionPeriod = 'year';
  financePeriod = 'year';
  hotellerieGastronomiePeriod = 'year';
  humanResourcesPeriod = 'year';
  industrialTechnicalPeriod = 'year';
  realEstatePeriod = 'year';
  topRolesClickedPeriod = 'year';
  averageDurationPeriod = 'year';
  whichCostumerWhichQueryPeriod = 'year'

  which_costumer_which_query_all_time;
  which_costumer_which_query_week;
  which_costumer_which_query_month;
  which_costumer_which_query_year;
  which_costumer_which_query_date;

  which_costumer_which_query_start_date: Date = new Date();
  which_costumer_which_query_end_date: Date = new Date();

  sector_all_time;
  sector_week;
  sector_month;
  sector_year;
  sector_date;

  sector_start_date: Date = new Date();
  sector_end_date: Date = new Date();
  
  region_all_time;
  region_week;
  region_month;
  region_year;

  canton_start_date: Date = new Date();
  canton_end_date: Date = new Date();

  finance_all_time;
  finance_week;
  finance_month;
  finance_year;

  finance_start_date: Date = new Date();
  finance_end_date: Date = new Date();

  humanResources_all_time;
  humanResources_week;
  humanResources_month;
  humanResources_year;

  human_resources_start_date: Date = new Date();
  human_resources_end_date: Date = new Date();

  hotellerieAndGastronomie_all_time;
  hotellerieAndGastronomie_week;
  hotellerieAndGastronomie_month;
  hotellerieAndGastronomie_year;

  hotellerie_and_gastronomie_start_date: Date = new Date();
  hotellerie_and_gastronomie_end_date: Date = new Date();

  industrialAndTechnichal_all_time;
  industrialAndTechnichal_week;
  industrialAndTechnichal_month;
  industrialAndTechnichal_year;

  industrial_and_tecnichal_start_date: Date = new Date();
  industrial_and_tecnichal_end_date: Date = new Date();

  realEstate_all_time;
  realEstate_week;
  realEstate_month;
  realEstate_year;

  real_estate_start_date: Date = new Date();
  real_estate_end_date: Date = new Date();

  most_searched_roles_all_time;
  most_searched_roles_week;
  most_searched_roles_month;
  most_searched_roles_year;

  most_searched_roles_start_date: Date = new Date();
  most_searched_roles_end_date: Date = new Date();
  
  
  average_duration_all_time;
  average_duration_week;
  average_duration_month;
  average_duration_now;
  
  average_duration_start_date: Date = new Date();
  average_duration_end_date: Date = new Date();

  
  financeChartNames = [];
  hotellerieAndGastronomieChartNames = [];
  humanResourcesChartNames = [];
  industrialAndTechnicalChartNames = [];
  realEstateChartNames = [];  

  maxDate: Date;

  constructor(private reportsService: ReportsService) {
    this.maxDate = new Date();
   }

  ngOnInit(): void {
    this.clicksPerSector();
    this.clicksPerFinance();
    this.clicksPerHumanResources();
    // this.clicksPerIndustrialAndTechnichal();
    this.clicksPerHotellerieAndGastronomie();
    this.clicksPerRealEstate();
    this.clicksPerRegion();
    // this.talentsInOnePager();
    this.averageDuration();
    this.averageDurationDateRange();
    this.mostSearchedRoles();
    this.usedDevice();

    this.loading = true;
    this.reportsService.getAdminReports().subscribe(response => {
      //sector
      this.sectorChart.series[0].setData(response.clicks_sector);
            
      this.sector_all_time = response.clicks_sector;
      this.sector_week = response.clicks_sector_week;
      this.sector_month = response.clicks_sector_month;
      this.sector_year = response.clicks_sector_year;

      // finance
      this.financeChart.series[0].setData(response.clicks_finance);
      this.setChartNames(this.financeChart, response.clicks_finance, this.financeChartNames);

      this.finance_all_time = response.clicks_finance;
      this.finance_week = response.clicks_finance_week;
      this.finance_month = response.clicks_finance_month;
      this.finance_year = response.clicks_finance_year;

      // human resources
      this.humanResourcesChart.series[0].setData(response.clicks_humanResources);
      this.setChartNames(this.humanResourcesChart, response.clicks_humanResources, this.humanResourcesChartNames);

      this.humanResources_all_time = response.clicks_humanResources;
      this.humanResources_week = response.clicks_humanResources_week;
      this.humanResources_month = response.clicks_humanResources_month;
      this.humanResources_year = response.clicks_humanResources_year;

      //hotellerie and gastronomie
      this.hotellerieAndGastronomieChart.series[0].setData(response.clicks_hotellerieAndGastronomie);
      this.setChartNames(this.hotellerieAndGastronomieChart, response.clicks_hotellerieAndGastronomie, this.hotellerieAndGastronomieChartNames);

      this.hotellerieAndGastronomie_all_time = response.clicks_hotellerieAndGastronomie;
      this.hotellerieAndGastronomie_week = response.clicks_hotellerieAndGastronomie_week;
      this.hotellerieAndGastronomie_month = response.clicks_hotellerieAndGastronomie_month;
      this.hotellerieAndGastronomie_year = response.clicks_hotellerieAndGastronomie_year;

      //industrial and technical
      // this.industrialAndTechnichalChart.series[0].setData(response.clicks_industrialAndTechnichal);
      // this.setChartNames(this.industrialAndTechnichalChart, response.clicks_industrialAndTechnichal, this.industrialAndTechnicalChartNames);

      // this.industrialAndTechnichal_all_time = response.clicks_industrialAndTechnichal;
      // this.industrialAndTechnichal_week = response.clicks_industrialAndTechnichal_week;
      // this.industrialAndTechnichal_month = response.clicks_industrialAndTechnichal_month;
      // this.industrialAndTechnichal_year = response.clicks_industrialAndTechnichal_year;
      
      //real estate
      this.realEstatechart.series[0].setData(response.clicks_realEstate);
      this.setChartNames(this.realEstatechart, response.clicks_realEstate, this.realEstateChartNames);

      this.realEstate_all_time = response.clicks_realEstate;
      this.realEstate_week = response.clicks_realEstate_week;
      this.realEstate_month = response.clicks_realEstate_month;
      this.realEstate_year = response.clicks_realEstate_year;

      //region 
      this.regionChart.series[0].setData(response.clicks_region);

      this.region_all_time = response.clicks_region;
      this.region_week = response.clicks_region_week;
      this.region_month = response.clicks_region_month;
      this.region_year = response.clicks_region_year;

      //
      // this.talentsOPChart.series[0].setData(response.count_talents_op);

      //most searched roles
      this.mostSearchedRolesChart.series[0].setData(response.most_searched_roles);
      for(let i = 0; i < response.most_searched_roles.length; i++) {
        this.mostSearchedRolesChartNames.push(response.most_searched_roles[i].name);
      }
      this.mostSearchedRolesChart.xAxis[0].setCategories(this.mostSearchedRolesChartNames);

      this.most_searched_roles_all_time = response.most_searched_roles;
      this.most_searched_roles_week = response.most_searched_roles_week;
      this.most_searched_roles_month = response.most_searched_roles_month;
      this.most_searched_roles_year = response.most_searched_roles_year;

      //uded device
      this.usedDeviceChart.series[0].setData(response.used_devices);
      //which costumer which query
      this.whichCostumerWhichQuery = response.which_costumer_which_query;

      this.which_costumer_which_query_all_time = response.which_costumer_which_query;
      this.which_costumer_which_query_week = response.which_costumer_which_query_week;
      this.which_costumer_which_query_month = response.which_costumer_which_query_month;
      this.which_costumer_which_query_year = response.which_costumer_which_query_year;


      //which talents are searched with on the one pager
      this.whichTalentsWithOnePager = response.which_talents_op;
      //average duration
      this.average_duration_now = response.average_duration;
      this.averageDurationChart.series[0].setData(response.average_duration.app);
      this.averageDurationChart.series[1].setData(response.average_duration.onepager);
      let xValues = response.average_duration.app.map(item => Number(item.x));
      this.averageDurationChart.update({
        xAxis: {
          categories: [], // Clear categories
          type: 'linear', // Set the axis type to linear
          min: Math.min(...xValues), // Set the minimum value
          max: Math.max(...xValues) // Set the maximum value
        }
      });

      //candidate alert
      this.candidateAlertQuery = response.candidate_alert_query;
      //candidate days
      this.candidateDays = response.candidate_days;
      //which day and time active
      // console.log("day_time_active", response.day_time_active);
      this.dayTimeActive = response.day_time_active;

      this.loading = false;
    });

  }

  setChartNames(chart, list, array) {
    for(let i = 0; i < list.length; i++) {
      array.push(list[i].name);
    }
    chart.xAxis[0].setCategories(array);
  }

  changePeriod(period, item) {
    if(item == 'sector') {
      this.sectorPeriod = period;
      switch(period) {
        case 'week': 
          this.sectorChart.series[0].setData(this.sector_week);
        break;
        case 'month': 
          this.sectorChart.series[0].setData(this.sector_month);
        break;
        case 'year': 
          this.sectorChart.series[0].setData(this.sector_year);
        break;
        case 'date': 
          this.onSectorDateChange();
        break;
        default: 
          this.sectorChart.series[0].setData(this.sector_all_time);
      }
    }

    if(item == 'region') {
      this.regionPeriod = period;
      switch(period) {
        case 'week': 
          this.regionChart.series[0].setData(this.region_week);
        break;
        case 'month': 
          this.regionChart.series[0].setData(this.region_month);
        break;
        case 'year': 
          this.regionChart.series[0].setData(this.region_year);
        break;
        case 'date': 
          this.onCantonDateChange();
        break;
        default: 
          this.regionChart.series[0].setData(this.region_all_time);
      }
    }

    if(item == 'finance') {
      this.financePeriod = period;
      switch(period) {
        case 'week': 
          this.financeChart.series[0].setData(this.finance_week);
        break;
        case 'month': 
          this.financeChart.series[0].setData(this.finance_month);
        break;
        case 'year': 
          this.financeChart.series[0].setData(this.finance_year);
        break;
        case 'date': 
        this.onFinanceDateChange();
      break;
        default: 
          this.financeChart.series[0].setData(this.finance_all_time);
      }
    }

    if(item == 'hotellerie-gastronomie') {
      this.hotellerieGastronomiePeriod = period;
      switch(period) {
        case 'week': 
           this.hotellerieAndGastronomieChart.series[0].setData(this.hotellerieAndGastronomie_week);
        break;
        case 'month': 
           this.hotellerieAndGastronomieChart.series[0].setData(this.hotellerieAndGastronomie_month);
        break;
        case 'year': 
           this.hotellerieAndGastronomieChart.series[0].setData(this.hotellerieAndGastronomie_year);
        break;
        case 'date': 
        this.onHotellerieGastronomieDateChange();
      break;
        default: 
           this.hotellerieAndGastronomieChart.series[0].setData(this.hotellerieAndGastronomie_all_time);
      }
    }

    if(item == 'human-resources') {
      this.humanResourcesPeriod = period;
      switch(period) {
        case 'week': 
          this.humanResourcesChart.series[0].setData(this.humanResources_week);
        break;
        case 'month': 
          this.humanResourcesChart.series[0].setData(this.humanResources_month);
        break;
        case 'year': 
          this.humanResourcesChart.series[0].setData(this.humanResources_year);
        break;
        case 'date': 
        this.onHumanResourcesDateChange();
      break;
        default: 
          this.humanResourcesChart.series[0].setData(this.humanResources_all_time);
      }
    }

    // if(item == 'industrial-technical') {
    //   this.industrialTechnicalPeriod = period;
    //   switch(period) {
    //     case 'week': 
    //       this.industrialAndTechnichalChart.series[0].setData(this.industrialAndTechnichal_week);
    //     break;
    //     case 'month': 
    //       this.industrialAndTechnichalChart.series[0].setData(this.industrialAndTechnichal_month);
    //     break;
    //     case 'year': 
    //       this.industrialAndTechnichalChart.series[0].setData(this.industrialAndTechnichal_year);
    //     break;
    //     case 'date': 
    //     this.onIndustrialAndTecnichalDateChange();
    //   break;
    //     default: 
    //       this.industrialAndTechnichalChart.series[0].setData(this.industrialAndTechnichal_all_time);
    //   }
    // }

    if(item == 'real-estate') {
      this.realEstatePeriod = period;
      switch(period) {
        case 'week': 
          this.realEstatechart.series[0].setData(this.realEstate_week);
        break;
        case 'month': 
          this.realEstatechart.series[0].setData(this.realEstate_month);
        break;
        case 'year': 
          this.realEstatechart.series[0].setData(this.realEstate_year);
        break;
        case 'date': 
        this.onRealEstateDateChange();
      break;
        default: 
          this.realEstatechart.series[0].setData(this.realEstate_all_time);
      }
    }

    if(item == 'top-roles-clicked') {
      this.topRolesClickedPeriod = period;
      switch(period) {
        case 'week': 
          this.mostSearchedRolesChart.series[0].setData(this.most_searched_roles_week);

          this.mostSearchedRolesChartNames =[];
          for(let i = 0; i < this.most_searched_roles_week.length; i++) {
            this.mostSearchedRolesChartNames.push(this.most_searched_roles_week[i].name);
          }
          this.mostSearchedRolesChart.xAxis[0].setCategories(this.mostSearchedRolesChartNames);
        break;
        case 'month': 
          this.mostSearchedRolesChart.series[0].setData(this.most_searched_roles_month);
            
          this.mostSearchedRolesChartNames = [];
          for(let i = 0; i < this.most_searched_roles_month.length; i++) {
            this.mostSearchedRolesChartNames.push(this.most_searched_roles_month[i].name);
          }
          this.mostSearchedRolesChart.xAxis[0].setCategories(this.mostSearchedRolesChartNames);
        break;
        case 'year': 
          this.mostSearchedRolesChart.series[0].setData(this.most_searched_roles_year);
            
          this.mostSearchedRolesChartNames = [];
          for(let i = 0; i < this.most_searched_roles_year.length; i++) {
            this.mostSearchedRolesChartNames.push(this.most_searched_roles_year[i].name);
          }
          this.mostSearchedRolesChart.xAxis[0].setCategories(this.mostSearchedRolesChartNames);
        break;
        case 'date': 
          this.onMostSearchedRolesDateChange();
        break;
        default: 
          this.mostSearchedRolesChart.series[0].setData(this.most_searched_roles_all_time);
            
          this.mostSearchedRolesChartNames = [];
          for(let i = 0; i < this.most_searched_roles_all_time.length; i++) {
            this.mostSearchedRolesChartNames.push(this.most_searched_roles_all_time[i].name);
          }
          this.mostSearchedRolesChart.xAxis[0].setCategories(this.mostSearchedRolesChartNames);
      }
    }

    if(item == 'average-duration') {
      this.averageDurationPeriod = period;
      switch(period) {
        case 'date': 
          this.onAverageDurationChange();
        break;
        case 'year': 
        
        this.averageDurationChart.series[0].setData(this.average_duration_now.app);
        this.averageDurationChart.series[1].setData(this.average_duration_now.onepager);
        
        let xValues = this.average_duration_now.app.map(item => Number(item.x));
          this.averageDurationChart.update({
            xAxis: {
              categories: [], // Clear categories
              type: 'linear', // Set the axis type to linear
              min: Math.min(...xValues), // Set the minimum value
              max: Math.max(...xValues) // Set the maximum value
            }
          });
        break;
        default:
      }
    }

    if(item == 'which-costumer-which-query') {
      this.whichCostumerWhichQueryPeriod = period;
      switch(period) {
        case 'week': 
          this.whichCostumerWhichQuery = this.which_costumer_which_query_week;
        break;
        case 'month': 
        this.whichCostumerWhichQuery = this.which_costumer_which_query_month;
        break;
        case 'year': 
        this.whichCostumerWhichQuery = this.which_costumer_which_query_year;
        break;
        case 'date': 
        this.onWhichCostumerWhichQueryChange();
      break;
        default: 
        this.whichCostumerWhichQuery = this.which_costumer_which_query_all_time;
      }
    }
   
  }

  //this is the part that we get the data based on the date range:
  onSectorDateChange() {
    if(this.sector_start_date && this.sector_end_date) {
      let start = moment(this.sector_start_date).format('YYYY-MM-DD');
      let end = moment(this.sector_end_date).format('YYYY-MM-DD');
      console.log("start: ", start);
      console.log("end: ", end);
      this.reportsService.getSectorReportsByDate({from: start, to: end}).subscribe(response => {
        this.sectorChart.series[0].setData(response['clicks_sector_date']);
      });
    }
  }

  onCantonDateChange() {
    if(this.canton_start_date && this.canton_end_date) {
      let start = moment(this.canton_start_date).format('YYYY-MM-DD');
      let end = moment(this.canton_end_date).format('YYYY-MM-DD');
      this.reportsService.getCantonReportsByDate({from: start, to: end}).subscribe(response => {
        this.regionChart.series[0].setData(response['clicks_region_date']);
      });
    }
  }

  onFinanceDateChange() {
    if(this.finance_start_date && this.finance_end_date) {
      let start = moment(this.finance_start_date).format('YYYY-MM-DD');
      let end = moment(this.finance_end_date).format('YYYY-MM-DD');
      this.reportsService.getFinanceReportsByDate({from: start, to: end}).subscribe(response => {
        this.financeChart.series[0].setData(response['clicks_finance_date']);
      });
    }
  }

  onHotellerieGastronomieDateChange() {
    if(this.hotellerie_and_gastronomie_start_date && this.hotellerie_and_gastronomie_end_date) {
      let start = moment(this.hotellerie_and_gastronomie_start_date).format('YYYY-MM-DD');
      let end = moment(this.hotellerie_and_gastronomie_end_date).format('YYYY-MM-DD');
      this.reportsService.getHotellerieGastronomieReportsByDate({from: start, to: end}).subscribe(response => {
        this.hotellerieAndGastronomieChart.series[0].setData(response['clicks_hotellerieAndGastronomie_date']);
      });
    }
  }

  onHumanResourcesDateChange() {
    if(this.human_resources_start_date && this.human_resources_end_date) {
      let start = moment(this.human_resources_start_date).format('YYYY-MM-DD');
      let end = moment(this.human_resources_end_date).format('YYYY-MM-DD');
      this.reportsService.getHumanResourcesReportsByDate({from: start, to: end}).subscribe(response => {
        this.humanResourcesChart.series[0].setData(response['clicks_humanResources_date']);
      });
    }
  }

  // onIndustrialAndTecnichalDateChange() {
  //   if(this.industrial_and_tecnichal_start_date && this.industrial_and_tecnichal_end_date) {
  //     let start = moment(this.industrial_and_tecnichal_start_date).format('YYYY-MM-DD');
  //     let end = moment(this.industrial_and_tecnichal_end_date).format('YYYY-MM-DD');
  //     this.reportsService.getIndustrialTecnichalReportsByDate({from: start, to: end}).subscribe(response => {
  //       this.industrialAndTechnichalChart.series[0].setData(response['clicks_industrialAndTechnichal_date']);
  //     });
  //   }
  // }

  onRealEstateDateChange() {
    if(this.real_estate_start_date && this.real_estate_end_date) {
      let start = moment(this.real_estate_start_date).format('YYYY-MM-DD');
      let end = moment(this.real_estate_end_date).format('YYYY-MM-DD');
      this.reportsService.getRealEstateReportsByDate({from: start, to: end}).subscribe(response => {
        this.realEstatechart.series[0].setData(response['clicks_realEstate_date']);
      });
    }
  }

  onMostSearchedRolesDateChange() {
    if(this.most_searched_roles_start_date && this.most_searched_roles_end_date) {
      let start = moment(this.most_searched_roles_start_date).format('YYYY-MM-DD');
      let end = moment(this.most_searched_roles_end_date).format('YYYY-MM-DD');
      this.reportsService.getMostSearchedRolesReportsByDate({from: start, to: end}).subscribe(response => {
        this.mostSearchedRolesChart.series[0].setData(response['most_searched_roles_date']);
            
        this.mostSearchedRolesChartNames = [];
        for(let i = 0; i < response['most_searched_roles_date'].length; i++) {
          this.mostSearchedRolesChartNames.push(response['most_searched_roles_date'][i].name);
        }
        this.mostSearchedRolesChart.xAxis[0].setCategories(this.mostSearchedRolesChartNames);
      });
    }
  }

  onWhichCostumerWhichQueryChange() {
    if(this.which_costumer_which_query_start_date && this.which_costumer_which_query_end_date) {
      let start = moment(this.which_costumer_which_query_start_date).format('YYYY-MM-DD');
      let end = moment(this.which_costumer_which_query_end_date).format('YYYY-MM-DD');
      this.reportsService.getWhichCostumerWhichQueryReportsByDate({from: start, to: end}).subscribe(response => {
        this.whichCostumerWhichQuery = response.which_costumer_which_query_date;
      });
    }
  }

  onAverageDurationChange() {
    // Cancel previous API request
    this.destroy$.next();
    this.destroy$.complete();
  
    // Create a new subject for the next API request
    this.destroy$ = new Subject<void>();
  
    if (this.average_duration_start_date && this.average_duration_end_date) {
      let start = moment(this.average_duration_start_date).format('YYYY-MM-DD');
      let end = moment(this.average_duration_end_date).format('YYYY-MM-DD');

      this.reportsService
        .getAverageDurationReportsByDate({ from: start, to: end })
        .pipe(
          takeUntil(this.destroy$),
          switchMap(response => {
            this.averageDurationDateRangeChart.series[0].setData(response.average_duration_date.app);
            this.averageDurationDateRangeChart.series[1].setData(response.average_duration_date.onepager);
            let xValues = response.average_duration_date.app.map(item => Number(item.x));
            this.averageDurationChart.update({
              xAxis: {
                categories: [],
                type: 'linear',
                min: Math.min(...xValues),
                max: Math.max(...xValues)
              }
            });
  
            // You can return an observable if needed
            return of(response);
          })
        )
        .subscribe();
    }
  }
  

  //this is the part that we create the charts from:
  clicksPerSector() {
    this.sectorChart = Highcharts.chart('clicksPerSector', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 400,
        marginBottom: 40,
      },
      title: {
        text: null
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        x: 0,
        y: 0,
        itemMarginBottom: 8,
        itemWidth: 250,
        itemStyle: {
            lineHeight: '18px',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#53565A'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          size: 280,
          showInLegend: true,
          colors: [
            '#53565A', 
            '#212121', 
            '#b3b3b3', 
            '#dadada',
            '#888888f0',
          ],
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            connectorWidth: 0,
            distance: "-30%",
            enabled: true,
            format: '{y}',
            color: "#fff",
            style: {
              textOutline: 'none' ,
            }
          },
          point: {
            events: {
             legendItemClick: function(){
              return false;
             }
            }
          }
        }
      },
      series: [{
        colorByPoint: true,
        type: undefined,
        data: []
      }],
      credits: {
        enabled: false
      },
    });
  };

  clicksPerFinance() {
    this.financeChart = Highcharts.chart('clicksPerFinance', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 400,
        marginBottom: 85,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
        categories: ['Buchhaltung', 'Controlling', 'Leitung Finanzen', 'Weitere Talente'],
        crosshair: true
        },
        yAxis: {
          softMin: 0,
          softMax: 1,
          title: {
              text: null
          }
      },
      plotOptions: {
        column: {
          minPointLength: 3,
          pointWidth: 35,
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none'
                },
                color: "#53565a"
            }
        }
    },
      series: [{
        type: undefined,
        color: "#53565a",
        colorByPoint: false,
        data: [],
        showInLegend: false
      }],
      credits: {
        enabled: false
      },
    });
  };

  clicksPerHumanResources() {
    this.humanResourcesChart = Highcharts.chart('clicksPerHumanResources', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 400,
        marginBottom: 40,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
        categories: ['HR Manager','Leitung HR', 'Payroll', 'Weitere Talente'],
        crosshair: true
        
        },
        yAxis: {
          softMin: 0,
          softMax: 1,
          title: {
              text: null
          }
      },
      plotOptions: {
        column: {
          minPointLength: 3,
          pointWidth: 35,
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none'
                },
                color: "#53565a"
            }
        }
    },
      series: [{
        type: undefined,
        color: "#53565a",
        colorByPoint: false,
        data: [],
        showInLegend: false
      }],
      credits: {
        enabled: false
      },
    });
  };

  clicksPerIndustrialAndTechnichal() {
    this.industrialAndTechnichalChart = Highcharts.chart('clicksPerIndustrialAndTechnichal', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 400,
        marginBottom: 40,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
        categories: ['Betriebs-elektrik', 'Gebäude', 'Service & Unterhalt', 'Weitere Talente'],
        crosshair: true
        },
      yAxis: {
        softMin: 0,
        softMax: 1,
        title: {
            text: null
        }
      },
      plotOptions: {
        column: {
          minPointLength: 3,
          pointWidth: 35,
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none'
                },
                color: "#53565a"
            }
        }
    },
      series: [{
        type: undefined,
        color: "#53565a",
        colorByPoint: false,
        data: [],
        showInLegend: false
      }],
      credits: {
        enabled: false
      },
    });
  };

  clicksPerHotellerieAndGastronomie() {
    this.hotellerieAndGastronomieChart = Highcharts.chart('clicksPerHotellerieAndGastronomie', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 400,
        marginBottom: 85,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
        categories: ['Direktion', 'Event & Bankett', 'Food & Beverage', 'Geschäfts-führung', 'HR', 'Küche', 'Rooms Division', 'Sales & Marketing', 'Weitere Talente'],
        crosshair: true
        },
        yAxis: {
          softMin: 0,
          softMax: 1,
          title: {
              text: null
          }
      },
      plotOptions: {
        column: {
          minPointLength: 3,
          pointWidth: 35,
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none'
                },
                color: "#53565a"
            },
        },
      },
      series: [{
        type: undefined,
        color: "#53565a",
        colorByPoint: false,
        data: [],
        showInLegend: false
      }],
      credits: {
        enabled: false
      },
    });
  };

  clicksPerRealEstate() {
    this.realEstatechart = Highcharts.chart('clicksPerRealEstate', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 400,
        marginBottom: 40,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
        categories: ['Bewirt-schaftung', 'Leitung Immobilien', 'Portfolio Manager', 'Weitere Talente'],
        crosshair: true
        },
        yAxis: {
          softMin: 0,
          softMax: 1,
          title: {
              text: null
          }
      },
      plotOptions: {
        column: {
          minPointLength: 3,
          pointWidth: 35,
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none'
                },
                color: "#53565a"
            }
        }
    },
      series: [{
        type: undefined,
        color: "#53565a",
        colorByPoint: false,
        data: [],
        showInLegend: false,
      }],
      credits: {
        enabled: false
      },
    });
  };

  clicksPerRegion() {
    this.regionChart = Highcharts.chart('clicksPerRegion', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'column',
        height: 400,
        marginBottom: 40,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
          categories: ["BE","BL","BS","FR","GE","GL","GR","JU","LU","NE","NW","OW","SG","SH","SO","SZ","TG","TI","UR","VD","VS","ZG","ZH","AG","AR","AI"],
        crosshair: true
      },
      yAxis: {
        softMin: 0,
        softMax: 1,
        title: {
            text: null
        }
      },
      plotOptions: {
        column: {
          minPointLength: 3
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none',
                
                },
                color: "#53565a"
            }
        }
    },
      series: [{
        colorByPoint: false,
        color: "#53565a",
        type: undefined,
        data: [],
        showInLegend: false
      }],
      credits: {
        enabled: false
      },
    });
  };

  // talentsInOnePager() {
  //   this.talentsOPChart = Highcharts.chart('talentsInOnePager', {
  //     chart: {
  //       plotBackgroundColor: null,
  //       plotBorderWidth: null,
  //       plotShadow: false,
  //       type: 'line',
  //       height: 400,
  //       marginBottom: 50,
  //     },
  //     title: {
  //       text: null
  //     },
  //     tooltip: {
  //       headerFormat: null,
  //       pointFormat: '{point.x} (Kalenderwoche) <br> {point.y} (Klicks)'
  //     },
  //     xAxis: {
  //       categories: [],
  //       crosshair: true,
  //       title: {
  //         text: 'Kalenderwoche',
  //       },
  //     },
  //     yAxis: {
  //       softMin: 0,
  //       softMax: 1,
  //       title: {
  //           text: 'Klicks'
  //       }
  //     },
  //     series: [{
  //       colorByPoint: false,
  //       color: "#53565a",
  //       type: undefined,
  //       data: [],
  //       showInLegend: false
  //     }],
  //     credits: {
  //       enabled: false
  //     },
  //   });
  // }

  averageDuration() {
    this.averageDurationChart = Highcharts.chart('averageDuration', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'line',
        height: 450,
        marginBottom: 100,
      },
      title: {
        text: null
      },
      tooltip: {
        headerFormat: null,
        pointFormat: '{point.name} <br> {point.z}'
      },
      xAxis: {
        categories: [],
        crosshair: true,
        title: {
          text: 'Week',
        },
      },
      yAxis: {
        title: {
          text: 'Minutes'
        },
      },
      series: [
      {
        name: 'pro Besuch',
        colorByPoint: false,
        color: "#53565a",
        type: undefined,
        data: [],
        showInLegend: true
      },
      {
        name: 'pro Talent/One Pager',
        colorByPoint: false,
        color: "#8F8F8F",
        type: undefined,
        data: [],
        showInLegend: true,
      }
    ],
      credits: {
        enabled: false
      },
    });
  }

  averageDurationDateRange() {
    this.averageDurationDateRangeChart = Highcharts.chart('averageDurationDateRange', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'line',
        height: 450,
        marginBottom: 100,
      },
      title: {
        text: null
      },
      tooltip: {
        headerFormat: null,
        pointFormat: '{point.name} <br> {point.z} <br> {point.week}'
      },
      xAxis: {
        categories: [],
        crosshair: true,
        title: {
          text: 'Week',
        },
      },
      yAxis: {
        title: {
          text: 'Minutes'
        },
      },
      series: [
      {
        name: 'pro Besuch',
        colorByPoint: false,
        color: "#53565a",
        type: undefined,
        data: [],
        showInLegend: true
      },
      {
        name: 'pro Talent/One Pager',
        colorByPoint: false,
        color: "#8F8F8F",
        type: undefined,
        data: [],
        showInLegend: true,
      }
    ],
      credits: {
        enabled: false
      },
    });
  }

  mostSearchedRoles() {
    this.mostSearchedRolesChart = Highcharts.chart('mostSearchedRoles', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'bar',
        height: 450,
        marginBottom: 50,
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      xAxis: {
        crosshair: true,
        labels: {
          overflow: 'justify',
          step: 1,
          rotation: 0,
          useHTML: true,
          style: {
            marginBottom: 10,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
      yAxis: {
        softMin: 0,
        softMax: 1,
        title: {
            text: null
        },
      },
      plotOptions: {
        column: {
          minPointLength: 3,
        },
        series: {
            dataLabels: {
                enabled: true,
                style: {
                  textOutline: 'none'
                },
                color: "#53565a"
            }
        }
    },
      series: [{
        colorByPoint: false,
        color: "#53565a",
        type: undefined,
        data: [],
        showInLegend: false
      }],
      credits: {
        enabled: false
      },
    });
  }

  usedDevice() {
    this.usedDeviceChart = Highcharts.chart('usedDevice', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 400,
        marginBottom: 40,
      },
      title: {
        text: null
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        x: 0,
        y: 0,
        itemMarginBottom: 8,
        itemWidth: 250,
        itemStyle: {
            lineHeight: '18px',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#53565A'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          size: 280,
          showInLegend: true,
          colors: [
            '#B3B3B3',
            '#53565A',
          ],
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            connectorWidth: 0,
            distance: "-30%",
            enabled: true,
            format: '{y}',
            color: "#fff",
            style: {
              textOutline: 'none'
            }
          },
          point: {
            events: {
             legendItemClick: function(){
              return false;
             }
            }
          }
        }
      },
      series: [{
        colorByPoint: true,
        type: undefined,
        data: []
      }],
      credits: {
        enabled: false
      },
    });
  };
}