import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../_services';
import { AuthService as SharedService } from '../../app/shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public submitted = false;
  userForm: FormGroup;
  public showPassword = false;
  public incorrectData = false;
  public isLoggingIn = false;

  public returningUrl = null;

  public isAlertBeingShown = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('token');

    if (token) {
      this.router.navigate(['main']);
      return;
    }

    this.isAlertBeingShown = sessionStorage.getItem('browserAlertShown') ? false : true;

    const returnUrl = this.sharedService.getReturnUrl();

    if (returnUrl) {
      console.log("returnUrl: ", returnUrl);
      const decodedReturnUrl = decodeURIComponent(returnUrl);
      this.navigateWithRetry(decodedReturnUrl);
      this.returningUrl = decodedReturnUrl;
      console.log("Navigating with returnUrl: ", decodedReturnUrl);
    }

    this.userForm = new FormGroup({
        'email' : new FormControl(null, Validators.required),
        'password' : new FormControl(null, Validators.required)
    });
  }

  navigateWithRetry(url: string, retries = 1): void {
    if (retries <= 0) {
      console.error('Navigation failed, redirecting to default route.');
      this.router.navigate(['main']);
      return;
    }
  
    this.router.navigateByUrl(url).then(success => {
      if (!success) {
        console.error(`Navigation failed, retrying (${retries} attempts remaining).`);
        this.navigateWithRetry(url, retries - 1);
      }
    });
  }

  login(): void {
    this.submitted = true;
  
    if (!this.userForm.valid) {
      return;
    }
  
    this.authService.login(this.userForm.value).subscribe(
      response => {
        this.isLoggingIn = true;
        localStorage.setItem('token', response.data.token);
        const user = response.data.user;
        console.log(response.data.user);
        localStorage.setItem('user', JSON.stringify(user));
        this.userForm.reset();
  
        console.log("this.returningUrl");
        console.log(this.returningUrl);
        if (this.returningUrl.includes('?')) {
          this.navigateWithRetry(this.returningUrl);
        } else {
          console.log(user.role);
          if (user.role === 'admin') {
            this.router.navigate(['admin']);
          } else {
            this.router.navigate(['main']);
          }
        }
      },
      error => {
        this.incorrectData = true;
      }
    );
  }
  
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
