import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private returnUrl: string;
  
  constructor() { }

  isLoggedIn() {
    return !!localStorage.getItem('token');
  }

  hasSubscription() {
      const user = JSON.parse(localStorage.getItem('user'))
      // console.log(user);
      if (user) {
        const now = new Date();
        const subscription = new Date(user.subscription);
        // console.log(subscription.getTime());
        // console.log(now.getTime());
        if (+subscription.setHours(0,0,0,0) >= +now.setHours(0,0,0,0) || user.role === 'admin') {
          return true;
        }
      }
      return false;
  }

  setReturnUrl(url: string): void {
    this.returnUrl = url;
  }

  getReturnUrl(): string {
    return this.returnUrl || '/'; // Default to '/' if no returnUrl is set
  }

  isAdminLoggedIn() {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    return token && user.role === 'admin';
  }
}
