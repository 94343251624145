import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-alert-dialog',
  templateUrl: './browser-alert-dialog.component.html',
  styleUrls: ['./browser-alert-dialog.component.css']
})
export class BrowserAlertDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}