<app-header></app-header>
<div class="body">
    <h3 class="title mv-4">Meine Favoriten</h3>
    <div class="results">
        <div class="result-box" *ngFor="let talent of talents">
          <div class="result-image">
            <img src="{{getImageUrl(talent)}}" (error)="onImgError($event, talent.sector_id)" alt="sector image"/>
          </div>
          <div class="result-data">
            <img *ngIf="talent.isFav" src="../../../assets/images/Icons/like-hover.png" alt="favorite" class="favorite-icon" matTooltip="Talent entfernen" (click)="removeFromFavorites(talent)"/>
            <img *ngIf="!talent.isFav" src="../../assets/images/Icons/like.png" alt="favorite" class="favorite-icon" matTooltip="Talent speichern" (click)="addToFavorites(talent)"/>
            <p class="result-text role-icon dif-font">{{talent.current_position}}</p>
            <p class="result-text role-location dif-font">{{talent.city}}</p>
            <a class="result-text one-pager dif-font"  target="_blank"  [routerLink]="['/one-pager']" [queryParams]="{talent_id: talent.manum, from: 'favorites'}">One-Pager anzeigen</a>
            <!-- <a class="result-text one-pager" [routerLink]="['/one-pager']" [queryParams]="{talent_id: talent.id}">View One-Pager</a> -->
          </div>
        </div>
        <div *ngIf="talents.length === 0">
          <p style="color: #808080">Keine gespeicherten Talente gefunden!</p>
        </div>
    </div>
</div>