import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class ReportsService {
    constructor(private http: HttpClient) {
    }

    getAdminReports() {
        return this.http.get<any>(`${environment.apiUrl}/admin-reports`);
    }

    getSectorReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/sector-reports-by-date`, params);
    }

    getCantonReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/canton-reports-by-date`, params);
    }

    getFinanceReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/finance-reports-by-date`, params);
    }

    getHotellerieGastronomieReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/hotellerie-and-gastronomie-reports-by-date`, params);
    }

    getHumanResourcesReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/human-resources-reports-by-date`, params);
    }

    getIndustrialTecnichalReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/industrial-and-tecnichal-reports-by-date`, params);
    }

    getRealEstateReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/real-estate-reports-by-date`, params);
    }

    getMostSearchedRolesReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/most-searched-roles-reports-by-date`, params);
    }

    getWhichCostumerWhichQueryReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/which-costumer-which-query-reports-by-date`, params);
    }

    getAverageDurationReportsByDate(params) {
        return this.http.post<any>(`${environment.apiUrl}/average-duration-reports-by-date`, params);
    }
}