import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.css']
})
export class AddRoleDialogComponent implements OnInit {
  name = '';
  allRoles = [];
  role_group = [];
  sectors = [];
  addRoleForm: FormGroup;
  public isInValid !: boolean; 
  isImageSelected = false;
  selectedImage = null;
  files: File[] = [];
  
  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<AddRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private mainService: MainService,
  ) { }

  ngOnInit(): void {
    this.mainService.getAllRoles().subscribe(response => {
      this.allRoles = response.roles;
    });
    this.mainService.getSectors().subscribe(response => {
      this.sectors = response.sectors;
    });
    
    this.role_group = this.allRoles;
    this.addRoleForm = new FormGroup({
      'name' : new FormControl(null, Validators.required),
      'sector_group' : new FormControl(null, Validators.required),
      'role_group' : new FormControl(null, Validators.required),
    })
  }

  selectSector(sector) {
    this.addRoleForm.value.role_group = null;
    let roles = [];
    for(let i = 0; i < this.allRoles.length; i++) {
      if(this.allRoles[i].sector_id == sector.id) {
        roles.push(this.allRoles[i]);
      }
    }
    this.role_group = roles;
  }

  add() {
    this.mainService.storeUnderRole({name: this.addRoleForm.value.name, role_group: this.addRoleForm.value.role_group}).subscribe(response => {
      // console.log(response);
    })
    this.dialogRef.close();
    console.log("added!");
  }

  onSelect(event) {
    this.files = event.addedFiles;
    // this.files.push(...event.addedFiles);
    console.log(event);
    console.log(event.addedFiles[0].name);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}
