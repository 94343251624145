<div class="space-between-items mb-2">
    <h2 mat-dialog-title class="mb-0">Funktionsgruppe bearbeiten</h2>
    <div>
        <button mat-icon-button color="#53565a" mat-dialog-close (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="editRoleForm" (ngSubmit)="save()">
        <div class="inline-flex">
            <div class="input-box-wrapper full-width">
                <label class="label-item">Nr. Reihenfolge <span class="required-label">*</span></label>
                <input type="number" formControlName="order_number" class="input-item available-width" [class.required-input]="(editRoleForm.get('order_number').touched && editRoleForm.get('order_number').invalid)">
                <div class="required-text" *ngIf="(editRoleForm.get('order_number').touched && editRoleForm.get('order_number').invalid)">
                    Nr. Reihenfolge ist erforderlich
                </div>
            </div>
        </div>
        <div class="inline-flex">
            <div class="input-box-wrapper">
                <label class="label-item">
                    Bezeichung der Funktionsgruppe 
                    <span class="required-label">*</span>
                    <div class="info" matTooltipPosition="right" matTooltip="Dieser Text erscheint auf der Hauptseite als Funktionsgruppe. Für einen Zeilenumbruch bitte '/' einfügen, z.B. 'weitere/Talente'."></div>
                </label>
                <input type="text" formControlName="wrapped_name" class="input-item" [class.required-input]="(editRoleForm.get('wrapped_name').touched && editRoleForm.get('wrapped_name').invalid)">
                <div class="required-text" *ngIf="(editRoleForm.get('wrapped_name').touched && editRoleForm.get('wrapped_name').invalid)">
                    Funktionsgruppe ist erforderlich
                </div>
              </div>
        </div>
        <div class="inline-flex mb-2">
            <div class="full-width">
                <label class="label-item">Branche <span class="required-label">*</span></label>
                <mat-select class="custom-select available-width" formControlName="sector">
                    <mat-option *ngFor="let s of sectors" value="{{s.id}}">
                        {{s.name}}
                    </mat-option>
                </mat-select>
                <div class="required-text" *ngIf="(editRoleForm.get('sector').touched && editRoleForm.get('sector').invalid)">
                    Branche ist erforderlich
                </div>
            </div>
        </div>
        <div class="centered">
            <button class="btn-elem mb-2" [disabled]="!editRoleForm.valid">speichern</button>
        </div>
    </form> 
</mat-dialog-content>