import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { FavoriteService, TalentService } from '../_services';
import { ConnectService } from '../_services/connect.service';
import { LikeService } from '../_services/like.service';

@Component({
  selector: 'app-one-pager',
  templateUrl: './one-pager.component.html',
  styleUrls: ['./one-pager.component.css']
})

export class OnePagerComponent implements OnInit {
  public activeDuration = 0;
  public timerId;

  constructor(private router: Router, private route: ActivatedRoute,
    private talentService: TalentService, private likeService: LikeService, private favoriteService: FavoriteService,
    private connectService: ConnectService, public dialog: MatDialog,
    ) { 
      this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

  public favorites = [];
  public connects = [];
  public isFav = false;
  public isConnected = false;

  public talentId = 0;
  public cantons = [];
  public sector_id = '';
  public role_id = '';
  public from = '';
  public talent;
  public age;
  public salary;
  public rate;
  public description;
  public rowsData = [];

 
  ngOnInit(): void {
    window.scrollTo(0, 0);

    document.addEventListener('visibilitychange', () => this.handleVisibilityChange());
    this.handleVisibilityChange();
    
    this.favoriteService.getFavorites().subscribe(response => {
      this.favorites = response.favorites;
    });

    this.connectService.getConnects().subscribe(response => {
      this.connects = response.connects;
    });

    this.route.queryParams.subscribe(resp => {
      if(resp.from) {
        this.from = resp.from;
      }

      if(resp.cantons) {
        this.cantons = resp.cantons;
      }
      
      if (resp.talent_id) {
        this.talentId = resp.talent_id;
        this.talentService.getTalent(this.talentId).subscribe(response => {
          this.talent = response.talent;
          
          this.sector_id = response.talent.sector_id;
          this.role_id = response.talent.role_id;

          this.isFav = this.favorites.indexOf(+response.talent.id) !== -1;
          this.isConnected = this.connects.indexOf(+response.talent.id) !== -1;

          var timeDiff = Math.abs(Date.now() - new Date(response.talent.birth_date).getTime());
          this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

          this.salary = Math.round(response.talent.salary_add);
          this.rate = Math.round(response.talent.employment_rate);
          this.description = response.talent.description;
          // this.description = response.talent.description.replaceAll("#", "").replaceAll("[", ". ").replaceAll("]", "");

          this.rowsData = this.description.split('#');
          this.rowsData.shift();
        });
      }
    });

    window.onbeforeunload = () => {
      this.close();
    };
  }

  handleVisibilityChange() {
    if (document.visibilityState == 'visible') {
      this.timerId = setInterval(() => {
        this.activeDuration++;
      }, 1000);
    } else {
      clearInterval(this.timerId);
    }
  }


  getRowData(text) {
    if(text == '') {} 
    else if(text.charAt(0) == '[') return text.replace('[', '').replace(']', '');
    else return text;
  }

  like() {
    this.likeService.storeLike({talent_id: this.talentId}).subscribe(response => {
      console.log('talent liked');
    })
  }

  removeLike() {
    this.likeService.deleteLike(this.talentId).subscribe(response => {
      console.log('unliked');
    })
  }
  
  addToFavorites() {
    this.isFav = true;
    this.favoriteService.storeFavorite({talent_id: this.talentId}).subscribe(response => {
      this.isFav = true;
    });
  }
  
  removeFromFavorites() {
    this.isFav = false;
    this.favoriteService.removeFavorite(this.talentId).subscribe(response => {
      this.isFav = false;
    });
  }

  connectT() {
    if(this.isConnected) {
      this.disconnectT()
    } else {
      this.isConnected = true;      
      this.connectService.storeConnect({talent_id: this.talentId}).subscribe(response => {
        this.isConnected = true;
      });
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "550px",
        data: {
          title: '', 
          message: 'Vielen Dank für Ihre Talentanfrage. Ihre Kontaktperson wird sich zeitnah mit Ihnen in Verbindung setzen.',
          confirmationOnly: true
        }
      });
    }
    
  }
  
  disconnectT() {
    this.isConnected = false;
    this.connectService.deleteConnect(this.talentId).subscribe(response => {
      this.isConnected = false;
    });
  }

  close() {
    console.log(this.activeDuration);
    this.talentService.onePagerClosed({duration: this.activeDuration}, this.talentId).subscribe(response => {
      window.close();
    });
    // if(this.from == 'main' || this.from == '') {
    //   this.router.navigate(['main'], {queryParams: {sector_id: this.sector_id, role_id: this.role_id, cantons: this.cantons}});
    // } else if (this.from == 'favorites') {
    //   this.router.navigate(['favorited-talents']);
    // } else if (this.from == 'connections') {
    //   this.router.navigate(['connections']);
    // }
  }
}
