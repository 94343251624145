<div class="centered" *ngIf="activePage=='register'">
    <div class="greeting-box">
      <!-- <h3 class="title">Willkommen zu</h3> -->
      <img src="../../assets/images/pse_logo.jpg" alt="PSE Logo" class="pse-logo-img"/>
    </div>
  
    <form [formGroup]="userForm" (ngSubmit)="register()" novalidate>
        <div class="inline-flex">
            <div class="input-box-wrapper">
              <label class="label-item">Vorname</label>
              <input type="text" formControlName="first_name" class="input-item" required [class.required-input]="(submitted && userForm.get('first_name').invalid) || (userForm.get('first_name').touched && userForm.get('first_name').invalid)">
              <div class="required-text" *ngIf="(submitted && userForm.get('first_name').invalid) || (userForm.get('first_name').touched && userForm.get('first_name').invalid)">
                Vorname ist erforderlich
              </div>
            </div>
            <div class="input-box-wrapper">
              <label class="label-item">Nachname</label>
              <input type="text" formControlName="last_name" class="input-item" required [class.required-input]="(submitted && userForm.get('last_name').invalid) || (userForm.get('last_name').touched && userForm.get('last_name').invalid)">
              <div class="required-text" *ngIf="(submitted && userForm.get('last_name').invalid) || (userForm.get('last_name').touched && userForm.get('last_name').invalid)">
                Nachname ist erforderlich
              </div>
            </div>
        </div>
        <div class="inline-flex">
            <div class="input-box-wrapper">
              <label class="label-item">Firmenname</label>
              <input type="text" formControlName="company_name" class="input-item" required [class.required-input]="(submitted && userForm.get('company_name').invalid) || (userForm.get('company_name').touched && userForm.get('company_name').invalid)">
              <div class="required-text" *ngIf="(submitted && userForm.get('company_name').invalid) || (userForm.get('company_name').touched && userForm.get('company_name').invalid)">
                Firmenname ist erforderlich
              </div>
            </div>
            <div class="input-box-wrapper">
              <label class="label-item">E-Mail</label>
              <input type="text" formControlName="email" (change)="emailExists = false;" class="input-item" required [class.required-input]="(submitted && userForm.get('email').invalid) || (userForm.get('email').touched && userForm.get('email').invalid) || emailExists">
              <div class="required-text" *ngIf="(submitted && userForm.get('email').invalid) || (userForm.get('email').touched && userForm.get('email').invalid)">
                E-Mail ist erforderlich
              </div>
              <div class="required-text" *ngIf="emailExists">
                E-Mail existiert bereits!
              </div>
            </div>
        </div>
        <div class="inline-flex">
            <div class="input-box-wrapper">
              <label class="label-item">Passwort</label>
              <div class="password-container">
                <input type="{{showPassword ? 'text' : 'password'}}" formControlName="password" class="input-item" required [class.required-input]="(submitted && userForm.get('password').invalid) || (userForm.get('password').touched && userForm.get('password').invalid)">
                <div class="show-password" [class.show]="showPassword" (click)="togglePasswordVisibility('password')"></div>
              </div>
              <div class="required-text" *ngIf="(submitted && userForm.get('password').invalid) || (userForm.get('password').touched && userForm.get('password').invalid)">
                {{userForm.get('password').errors.minlength ? 'Passwort muss mindestens 6 Zeichen lang sein' : 'Passwort ist erforderlich'}}
              </div>
            </div>
            <div class="input-box-wrapper">
              <label class="label-item">Passwortbestätigung</label>
              <div class="password-container">
                <input type="{{showConfirmPassword ? 'text' : 'password'}}" formControlName="password_confirmation" class="input-item" required [class.required-input]="(submitted && userForm.get('password_confirmation').invalid) || (userForm.get('password_confirmation').touched && userForm.get('password_confirmation').invalid)">
                <div class="show-password" [class.show]="showConfirmPassword" (click)="togglePasswordVisibility('confirmpassword')"></div>
              </div>
              <div class="required-text" *ngIf="(submitted && userForm.get('password_confirmation').invalid) || (userForm.get('password_confirmation').touched && userForm.get('password_confirmation').invalid)">
                Passwortbestätigung ist erforderlich
              </div>
            </div>
        </div>
  
        <div class="required-text mb-2" *ngIf="notSamePasswords">
          Das Bestätigungskennwort sollte mit dem Kennwort identisch sein
         </div>
  
         <div class="inline-flex">
          <div class="input-box-wrapper full-width">
            <label class="label-item">Promocode</label>
            <input formControlName="promocode" type="text" class="input-item available-width" (change)="checkPromocode()">
            <div class="required-text" *ngIf="promocodeInvalid">
              dieser Code ist nicht gültig
            </div>
          </div>
      </div>
        
      <button type="submit" class="btn-elem mb-2">Registrieren</button>
      <div class="v-centered-items" *ngIf="isRegistering">
        <img src="../../assets/images/loading.gif" alt="loading" style="width: 30px; height: 30px; margin-bottom: 15px;">
      </div>
    </form> 
    <p class="text">Sie haben bereits ein Konto? <a [routerLink]="['/login']" class="link-item">Hier einloggen.</a></p>
  </div>
  
  <form action="" *ngIf="activePage=='verification-code'">
    <div class="v-page-size v-centered-items">
        <img src="../../assets/images/pse_logo.jpg" alt="" class="v-image">
        <p class="v-title">Verifizierungs Code</p>
        <p class="v-text">Bitte überprüfen Sie Ihren E-Mail-Posteingang und Spam-Ordner auf den Bestätigungscode.</p>
  
        <div class="v-flexed-items v-mb-3">
            <code-input 
                #codeInput
                [isCodeHidden]="false"
                [codeLength]="4"
                (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onCodeCompleted($event)">
            </code-input>
        </div>
       
        <div class="v-flexed-items v-mb-3">
            <button class="v-btn-el" (click)="resend()">Code erneut senden</button>
            <button class="v-btn-el v-filled" (click)="verify()">Verifizieren</button>
        </div>
  
        <div *ngIf="isVerifying" class="v-centered-items">
          <img src="../../assets/images/loading.gif" alt="loading" style="width: 30px; height: 30px; margin-bottom: 15px;">
          <div>ihr code wird überprüft!</div>
        </div>
    </div>
  </form>
  
  <div class="v-page-size v-centered-items" *ngIf="activePage=='verifying'">
    <img src="../../assets/images/pse_logo.jpg" alt="" class="v-image">
    <p>Ihr Konto ist jetzt freigeschaltet und Sie können die Anwendung ab sofort nutzen.</p>
    <a class="link" [routerLink]="['/login']" class="btn-el">zurück zum Login</a>
  </div>