import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/_services';

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.css']
})
export class EditRoleDialogComponent implements OnInit {
  id = 0;
  name = '';
  sector = '';
  wrapped_name = '';
  orderNumber = 0;
  sectors = [];
  editRoleForm: FormGroup;
  public isInValid !: boolean; 

  @Output() submitClicked = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private mainService: MainService,
    public dialogRef: MatDialogRef<EditRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.mainService.getSectors().subscribe(response => {
      this.sectors = response.sectors;
      console.log(this.sectors);
    });
   }

  ngOnInit(): void {

    this.id = this.data.id;
    this.name = this.data.name;
    this.wrapped_name = this.data.wrapped_name;
    this.sector = this.data.sector;
    this.orderNumber = this.data.orderNumber;
    
    this.editRoleForm = new FormGroup({
      'wrapped_name' : new FormControl(this.wrapped_name, Validators.required),
      'sector' : new FormControl("" + this.sector + "", Validators.required),
      'order_number' : new FormControl(this.orderNumber, Validators.required)
    })
  }

  save() {
    let roleName = this.editRoleForm.value.wrapped_name.replaceAll("/", "");
    this.mainService.updateRole({name: roleName, wrapped_name: this.editRoleForm.value.wrapped_name, sector_id: this.editRoleForm.value.sector, order_number: this.editRoleForm.value.order_number}, this.id).subscribe(response => {
      console.log(response);
    })
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
