import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from '../../_services/main.service';

@Component({
  selector: 'app-edit-under-role-dialog',
  templateUrl: './edit-under-role-dialog.component.html',
  styleUrls: ['./edit-under-role-dialog.component.css']
})
export class EditUnderRoleDialogComponent implements OnInit {
  id = null;
  name = '';
  role_group = '';
  sector = '';
  role_groups = [];
  sectors = [];
  allRoles = [];
  editUnderRoleForm: FormGroup;
  public isInValid !: boolean; 
  isImageSelected = false;
  selectedImage = null;
  files: File[] = [];
  
  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<EditUnderRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private mainService: MainService,
  ) { }

  ngOnInit(): void {
    this.mainService.getSectors().subscribe(response => {
      this.sectors = response.sectors;
    });

    this.mainService.getAllRoles().subscribe(response => {
      let roles = [];
      this.role_groups = response.roles;
      this.allRoles = response.roles;
      for(let i = 0; i < this.role_groups.length; i++) {
        if(this.role_groups[i].sector_id == this.sector) {
          roles.push(this.role_groups[i]);
        }
      }
      this.role_groups = roles;
    });

    this.id = this.data.id;
    this.name = this.data.name;
    this.role_group = this.data.role_group;
    this.sector = this.data.sector;

    this.editUnderRoleForm = new FormGroup({
      'name' : new FormControl(this.name, Validators.required),
      'sector_group' : new FormControl("" + this.sector + "", Validators.required),
      'role_group' : new FormControl("" + this.role_group + "", Validators.required),
    })
  }

  selectSector(sector) {
    this.editUnderRoleForm.value.role_group = null;
    let roles = [];
    for(let i = 0; i < this.allRoles.length; i++) {
      if(this.allRoles[i].sector_id == sector.id) {
        roles.push(this.allRoles[i]);
      }
    }
    this.role_groups = roles;
  }

  save() {
    this.mainService.updateUnderRole({name: this.editUnderRoleForm.value.name, role_group: this.editUnderRoleForm.value.role_group}, this.id).subscribe(response => {
      // console.log(response);
    })
    this.dialogRef.close();
  }

  onSelect(event) {
    this.files = event.addedFiles;
    // this.files.push(...event.addedFiles);
    console.log(event);
    console.log(event.addedFiles[0].name);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}
