<app-admin-header></app-admin-header>
<div class="bar-width">
    <mat-progress-bar mode="indeterminate" *ngIf="loading" color="#53565a"></mat-progress-bar>
  </div>
<div class="page-wrapper">
    <div class="col-2">
        <div class="col-item">
            <h1 class="field-title">Klicks Branchen</h1>
            <div class="tabs-holder">
                <a class="tab-btn" [class.tab-btn-active]="sectorPeriod == 'week'" (click)="changePeriod('week', 'sector')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="sectorPeriod == 'month'" (click)="changePeriod('month', 'sector')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="sectorPeriod == 'year'" (click)="changePeriod('year', 'sector')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="sectorPeriod == 'date'" (click)="changePeriod('date', 'sector')">Benutzerdefiniert</a>
                <!-- <a class="tab-btn"[class.tab-btn-active]="sectorPeriod == 'all-time'" (click)="changePeriod('all-time', 'sector')">All Time</a> -->
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="sectorPeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [(ngModel)]="sector_start_date" (dateInput)="onSectorDateChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="sector_end_date" (dateInput)="onSectorDateChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>              
                </div>
            </div>
            <div class="graph-wrapper" id="clicksPerSector"></div>
        </div>
        <div class="col-item">
            <h1 class="field-title">Klicks Kantone</h1>
            <div class="tabs-holder">
                <a class="tab-btn" [class.tab-btn-active]="regionPeriod == 'week'" (click)="changePeriod('week', 'region')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="regionPeriod == 'month'" (click)="changePeriod('month', 'region')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="regionPeriod == 'year'" (click)="changePeriod('year', 'region')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="regionPeriod == 'date'" (click)="changePeriod('date', 'region')">Benutzerdefiniert</a>
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="regionPeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [(ngModel)]="canton_start_date" (dateInput)="onCantonDateChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="canton_end_date" (dateInput)="onCantonDateChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                </div>
                <!-- <a class="tab-btn"[class.tab-btn-active]="regionPeriod == 'all-time'" (click)="changePeriod('all-time', 'region')">All Time</a> -->
            </div>
          <div class="graph-wrapper" id="clicksPerRegion"></div>
        </div>
    </div>
    <div class="col-2">
        <div class="col-item">
            <h1 class="field-title">Klicks Finance</h1>
            <div class="tabs-holder">
                <a class="tab-btn" [class.tab-btn-active]="financePeriod == 'week'" (click)="changePeriod('week', 'finance')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="financePeriod == 'month'" (click)="changePeriod('month', 'finance')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="financePeriod == 'year'" (click)="changePeriod('year', 'finance')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="financePeriod == 'date'" (click)="changePeriod('date', 'finance')">Benutzerdefiniert</a>
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="financePeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [(ngModel)]="finance_start_date" (dateInput)="onFinanceDateChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="finance_end_date" (dateInput)="onFinanceDateChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                </div>
                <!-- <a class="tab-btn"[class.tab-btn-active]="financePeriod == 'all-time'" (click)="changePeriod('all-time', 'finance')">All Time</a> -->
            </div>
            <div class="graph-wrapper" id="clicksPerFinance"></div>
        </div>
        <div class="col-item">
            <h1 class="field-title">Klicks Hotellerie & Gastronomie</h1>
            <div class="tabs-holder">
                <a class="tab-btn" [class.tab-btn-active]="hotellerieGastronomiePeriod == 'week'" (click)="changePeriod('week', 'hotellerie-gastronomie')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="hotellerieGastronomiePeriod == 'month'" (click)="changePeriod('month', 'hotellerie-gastronomie')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="hotellerieGastronomiePeriod == 'year'" (click)="changePeriod('year', 'hotellerie-gastronomie')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="hotellerieGastronomiePeriod == 'date'" (click)="changePeriod('date', 'hotellerie-gastronomie')">Benutzerdefiniert</a>
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="hotellerieGastronomiePeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [(ngModel)]="hotellerie_and_gastronomie_start_date" (dateInput)="onHotellerieGastronomieDateChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="hotellerie_and_gastronomie_end_date" (dateInput)="onHotellerieGastronomieDateChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                </div>
                <!-- <a class="tab-btn"[class.tab-btn-active]="hotellerieGastronomiePeriod == 'all-time'" (click)="changePeriod('all-time', 'hotellerie-gastronomie')">All Time</a> -->
            </div>
            <div class="graph-wrapper" id="clicksPerHotellerieAndGastronomie"></div>
        </div>

    </div>
    <div class="col-2">
        <div class="col-item">
            <h1 class="field-title">Klicks Human Resources</h1>
            <div class="tabs-holder mb-10">
                <a class="tab-btn" [class.tab-btn-active]="humanResourcesPeriod == 'week'" (click)="changePeriod('week', 'human-resources')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="humanResourcesPeriod == 'month'" (click)="changePeriod('month', 'human-resources')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="humanResourcesPeriod == 'year'" (click)="changePeriod('year', 'human-resources')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="humanResourcesPeriod == 'date'" (click)="changePeriod('date', 'human-resources')">Benutzerdefiniert</a>
                <!-- <a class="tab-btn"[class.tab-btn-active]="humanResourcesPeriod == 'all-time'" (click)="changePeriod('all-time', 'human-resources')">All Time</a> -->
            </div>
            <div class="date-range-box mb-10">
                <mat-form-field appearance="fill" *ngIf="humanResourcesPeriod == 'date'">
                    <mat-label>Zeitraum auswählen</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Start date" [(ngModel)]="human_resources_start_date" (dateInput)="onHumanResourcesDateChange($event)">
                      <input matEndDate placeholder="End date" [(ngModel)]="human_resources_end_date" (dateInput)="onHumanResourcesDateChange($event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
            </div>
            <div class="graph-wrapper" id="clicksPerHumanResources"></div>
        </div>
        <!-- <div class="col-item">
            <h1 class="field-title">Klicks Industrial & Technical</h1>
            <div class="tabs-holder mb-10">
                <a class="tab-btn" [class.tab-btn-active]="industrialTechnicalPeriod == 'week'" (click)="changePeriod('week', 'industrial-technical')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="industrialTechnicalPeriod == 'month'" (click)="changePeriod('month', 'industrial-technical')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="industrialTechnicalPeriod == 'year'" (click)="changePeriod('year', 'industrial-technical')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="industrialTechnicalPeriod == 'date'" (click)="changePeriod('date', 'industrial-technical')">Benutzerdefiniert</a>
            </div>
            <div class="date-range-box mb-10">
                <mat-form-field appearance="fill" *ngIf="industrialTechnicalPeriod == 'date'">
                    <mat-label>Zeitraum auswählen</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="Start date" [(ngModel)]="industrial_and_tecnichal_start_date" (dateInput)="onIndustrialAndTecnichalDateChange($event)">
                      <input matEndDate placeholder="End date" [(ngModel)]="industrial_and_tecnichal_end_date" (dateInput)="onIndustrialAndTecnichalDateChange($event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
            </div>
            <div class="graph-wrapper" id="clicksPerIndustrialAndTechnichal"></div>
        </div> -->
        <div class="col-item">
            <h1 class="field-title">Klicks Real Estate</h1>
            <div class="tabs-holder mb-10">
                <a class="tab-btn" [class.tab-btn-active]="realEstatePeriod == 'week'" (click)="changePeriod('week', 'real-estate')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="realEstatePeriod == 'month'" (click)="changePeriod('month', 'real-estate')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="realEstatePeriod == 'year'" (click)="changePeriod('year', 'real-estate')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="realEstatePeriod == 'date'" (click)="changePeriod('date', 'real-estate')">Benutzerdefiniert</a>
                <!-- <a class="tab-btn"[class.tab-btn-active]="realEstatePeriod == 'all-time'" (click)="changePeriod('all-time', 'real-estate')">All Time</a> -->
            </div>
                <div class="date-range-box mb-10">
                    <mat-form-field appearance="fill" *ngIf="realEstatePeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" [(ngModel)]="real_estate_start_date" (dateInput)="onRealEstateDateChange($event)">
                            <input matEndDate placeholder="End date" [(ngModel)]="real_estate_end_date" (dateInput)="onRealEstateDateChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                </div>
                <div class="graph-wrapper" id="clicksPerRealEstate"></div>
        </div>
    </div>
    
    <div class="col-2">
        <!-- <div class="col-item">
            <h1 class="field-title">Wie viele Kandidaten werden mit One Pager angeschaut?</h1>
            <div class="graph-wrapper" id="talentsInOnePager"></div>
        </div> -->
    </div>

    <div class="col-2">
        <!-- <div class="col-item">
            <h1 class="field-title">Welche Talente werden mit One Pager angeschaut?</h1>
            <div class="table-wrapper">
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th>Talent ID</th>
                                <th>Branche</th>
                                <th>Funktion</th>
                                <th>Region</th>
                                <th>Anzahl Klicks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let talent of whichTalentsWithOnePager">
                                <td>{{talent.talent_id}}</td>
                                <td>{{talent.sector}}</td>
                                <td>{{talent.role}}</td>
                                <td>{{talent.region}}</td>
                                <td>{{talent.clicks}}</td>
                            </tr> 
                            <tr *ngIf="whichTalentsWithOnePager.length == 0">keine Berichte gefunden</tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>

    <div class="col-2">
        <div class="col-item">
            <h1 class="field-title">Ranking: Welche Profile wurden am häufigsten gesucht?</h1>
            <div class="tabs-holder">
                <a class="tab-btn" [class.tab-btn-active]="topRolesClickedPeriod == 'week'" (click)="changePeriod('week', 'top-roles-clicked')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="topRolesClickedPeriod == 'month'" (click)="changePeriod('month', 'top-roles-clicked')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="topRolesClickedPeriod == 'year'" (click)="changePeriod('year', 'top-roles-clicked')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="topRolesClickedPeriod == 'date'" (click)="changePeriod('date', 'top-roles-clicked')">Benutzerdefiniert</a>
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="topRolesClickedPeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [(ngModel)]="most_searched_roles_start_date" (dateInput)="onMostSearchedRolesDateChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="most_searched_roles_end_date" (dateInput)="onMostSearchedRolesDateChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                </div>
                <!-- <a class="tab-btn"[class.tab-btn-active]="topRolesClickedPeriod == 'all-time'" (click)="changePeriod('all-time', 'top-roles-clicked')">All Time</a> -->
            </div>
            <div class="graph-wrapper" id="mostSearchedRoles"></div>
        </div>
        <div class="col-item">
            <h1 class="field-title">Durchschnittliche Dauer auf PermServEasy</h1>
            <div class="tabs-holder">
                <a class="tab-btn" [class.tab-btn-active]="averageDurationPeriod == 'year'" (click)="changePeriod('year', 'average-duration')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="averageDurationPeriod == 'date'" (click)="changePeriod('date', 'average-duration')">Benutzerdefiniert</a>
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="averageDurationPeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [max]="maxDate" [(ngModel)]="average_duration_start_date" (dateInput)="onAverageDurationChange($event)" >
                          <input matEndDate placeholder="End date" [max]="maxDate" [(ngModel)]="average_duration_end_date" (dateInput)="onAverageDurationChange($event)" >
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                </div>
            </div>
            <div class="graph-wrapper" id="averageDuration" [ngStyle]="averageDurationPeriod == 'date' ? { 'display': 'none' } : { 'display': 'block' }"></div>
            <div class="graph-wrapper" id="averageDurationDateRange" [ngStyle]="averageDurationPeriod != 'date' ? { 'display': 'none' } : { 'display': 'block' }"></div>
            <!-- <div class="table-wrapper">
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th></th>
                                <th>Durchschnittliche Besuchsdauer in min</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let duration of averageDuration">
                                <td>{{duration.type}}</td>
                                <td>{{duration.time}}</td>
                            </tr>
                            <tr *ngIf="averageDuration.length == 0">keine Berichte gefunden</tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
        </div>
    </div>

    <!-- <div class="col-1">
        <div class="col-item">
        <h1 class="field-title">An welchem Wochentag, um welche Uhrzeit ist der Kunde auf PSE, Durchschnitt über alle Wochen?</h1>
        <div class="table-wrapper">
            <div class="table100 table800H border-table-100">
            <table class="border-table">
                <thead>
                    <tr class="table100-head">
                        <th>Tageszeit</th>
                        <th>Montag</th>
                        <th>Dienstag</th>
                        <th>Mittwoch</th>
                        <th>Donnerstag</th>
                        <th>Freitag</th>
                        <th>Samstag</th>
                        <th>Sonntag</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let day of dayTimeActive">
                        <td>{{day.day}}</td>
                        <td>{{day.mon}}</td>
                        <td>{{day.tue}}</td>
                        <td>{{day.wed}}</td>
                        <td>{{day.thu}}</td>
                        <td>{{day.fri}}</td>
                        <td>{{day.sat}}</td>
                        <td>{{day.sun}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
        </div>
    </div> -->
    
    <div class="col-2">
        <div class="col-item">
            <h1 class="field-title">Womit hat sich der Kunde eingeloggt?</h1>
            <div class="tabs-holder mb-10"></div>
            <div class="graph-wrapper" id="usedDevice"></div>
        </div>

        <div class="col-item">
            <h1 class="field-title">Welcher Kunde hat welche Suchanfragen eingegeben?</h1>
            <div class="tabs-holder mb-10">
                <a class="tab-btn" [class.tab-btn-active]="whichCostumerWhichQueryPeriod == 'week'" (click)="changePeriod('week', 'which-costumer-which-query')">Current Week</a>
                <a class="tab-btn" [class.tab-btn-active]="whichCostumerWhichQueryPeriod == 'month'" (click)="changePeriod('month', 'which-costumer-which-query')">Last Month</a>
                <a class="tab-btn" [class.tab-btn-active]="whichCostumerWhichQueryPeriod == 'year'" (click)="changePeriod('year', 'which-costumer-which-query')">YTD</a>
                <a class="tab-btn" [class.tab-btn-active]="whichCostumerWhichQueryPeriod == 'date'" (click)="changePeriod('date', 'which-costumer-which-query')">Benutzerdefiniert</a>
                <div class="date-range-box mb-10 ml-15">
                    <mat-form-field appearance="fill" *ngIf="whichCostumerWhichQueryPeriod == 'date'">
                        <mat-label>Zeitraum auswählen</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Start date" [(ngModel)]="which_costumer_which_query_start_date" (dateInput)="onWhichCostumerWhichQueryChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="which_costumer_which_query_end_date" (dateInput)="onWhichCostumerWhichQueryChange($event)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
                </div>
            </div>
            <div class="table-wrapper">
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th>Name</th>
                                <th>Vorname</th>
                                <th>Firma</th>
                                <th>Suche</th>
                                <th>Datum</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let costumer of whichCostumerWhichQuery">
                                <td>{{costumer.last_name}}</td>
                                <td>{{costumer.name}}</td>
                                <td>{{costumer.company}}</td>
                                <td>{{costumer.query}}</td>
                                <td class="pr-2">{{costumer.date | date: 'dd.MM.YYYY'}}</td>
                            </tr> 
                            <tr *ngIf="whichCostumerWhichQuery.length == 0">
                                <td colspan="5">keine Berichte gefunden</td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-2">
        <div class="col-item">
            <h1 class="field-title">Welcher Kunde hat einen Alert auf welche Suchanfrage erstellt?</h1>
            <div class="table-wrapper">
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th>Name</th>
                                <th>Vorname</th>
                                <th>Firma</th>
                                <th>Suche</th>
                                <th>Datum</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let candidate of candidateAlertQuery">
                                <td>{{candidate.last_name}}</td>
                                <td>{{candidate.name}}</td>
                                <td>{{candidate.company}}</td>
                                <td>{{candidate.query}}</td>
                                <td class="pr-2">{{candidate.date | date: 'dd.MM.YYYY'}}</td>
                            </tr> 
                            <tr *ngIf="candidateAlertQuery.length == 0">
                                <td colspan="5">keine Berichte gefunden</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-item">
            <h1 class="field-title">Nutzungstage pro Monat</h1>
            <div class="table-wrapper">
                <div class="table100">
                    <table>
                        <thead>
                            <tr class="table100-head">
                                <th>Name</th>
                                <th>Vorname</th>
                                <th>Firma</th>
                                <th class="center-align-text">Anzahl Besuche</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let candidate of candidateDays">
                                <td>{{candidate.last_name}}</td>
                                <td>{{candidate.name}}</td>
                                <td>{{candidate.company}}</td>
                                <td class="center-align-text">{{candidate.day}}</td>
                            </tr>
                            <tr *ngIf="candidateDays.length == 0">
                                <td colspan="4">keine Berichte gefunden</td>  
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>        
    </div>