import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class MainService {
    constructor(private http: HttpClient) {
    }

    getSectors() {
        return this.http.get<any>(`${environment.apiUrl}/sectors`);
    }

    getSector(id) {
        return this.http.get<any>(`${environment.apiUrl}/sector/${id}`);
    }

    storeSector(data) {
        return this.http.post<any>(`${environment.apiUrl}/sector`, data);
    }

    updateSector(data, id) {
        return this.http.put<any>(`${environment.apiUrl}/sector/${id}`, data);
    }

    deleteSector(id) {
        return this.http.delete<any>(`${environment.apiUrl}/sector/${id}`);
    }



    getAllRoles() {
        return this.http.get<any>(`${environment.apiUrl}/all-roles`);
    }

    getRoles(sector_id) {
        return this.http.get<any>(`${environment.apiUrl}/roles?sector_id=${sector_id}`);
    }

    getRole(id) {
        return this.http.get<any>(`${environment.apiUrl}/role/${id}`);
    }

    storeRole(data) {
        return this.http.post<any>(`${environment.apiUrl}/role`, data);
    }

    updateRole(data, id) {
        return this.http.put<any>(`${environment.apiUrl}/role/${id}`, data);
    }

    deleteRole(id) {
        return this.http.delete<any>(`${environment.apiUrl}/role/${id}`);
    }


    getUnderRoles() {
        return this.http.get<any>(`${environment.apiUrl}/under-roles`);
    }

    getUnderRole(id) {
        return this.http.get<any>(`${environment.apiUrl}/under-roles/${id}`);
    }

    storeUnderRole(data) {
        return this.http.post<any>(`${environment.apiUrl}/under-role`, data);
    }

    updateUnderRole(data, id) {
        return this.http.put<any>(`${environment.apiUrl}/under-role/${id}`, data);
    }

    deleteUnderRole(id) {
        return this.http.delete<any>(`${environment.apiUrl}/under-role/${id}`);
    }


    
   
    

    


    // '/role/' + id; == `/role/${id}`;


    // krejt sectors edhe roles edhe talent funksionet e backendit me i ba ktu me logjiken si me lart



    // get example
    // getRole(id) {
    //     return this.http.get<any>(`${environment.apiUrl}/role/${id}`);
    // }

    // post example
    // login(data) {
    //     return this.http.post<any>(`${environment.apiUrl}/auth/login`, data);
    // }

    // put example
    // login(data, id) {
    //     return this.http.put<any>(`${environment.apiUrl}/auth/login/${id}`, data);
    // }

    // delete example
    // login(id) {
    //     return this.http.delete<any>(`${environment.apiUrl}/auth/login/${id}`);
    // }
}

