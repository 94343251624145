import { Component, OnInit, ViewChild } from '@angular/core';
import {CodeInputComponent} from 'angular-code-input';
import { Router } from '@angular/router';


@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.css']
})
export class VerificationCodeComponent implements OnInit {

  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.router.navigate(['verification-code/verifying']);

  }

  verify() {
    this.router.navigate(['verification-code/verifying']);
  }

}
