import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  user = JSON.parse(localStorage.getItem('user'));

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  logout() {
    
    this.authService.logout().subscribe(() => {
      this.router.navigate(['']);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }, err => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.router.navigate(['']);
    });
    this.router.navigate(['']);
  }
}
