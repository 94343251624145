import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {
    }

    login(data) {
        return this.http.post<any>(`${environment.apiUrl}/auth/login`, data);
    }

    logout() {
        return this.http.post<any>(`${environment.apiUrl}/auth/logout`, {});
    }

    sendVerification(data) {
        return this.http.post<any>(`${environment.apiUrl}/auth/send-verification`, data);
    }

    register(data) {
        return this.http.post<any>(`${environment.apiUrl}/auth/register`, data);
    }

    forgotPassword(data) {
        return this.http.post<any>(`${environment.apiUrl}/forgot-password`, data);
    }

    resetPassword(data) {
        return this.http.post<any>(`${environment.apiUrl}/reset-password`, data);
    }
}
