import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { OnePagerComponent } from './one-pager/one-pager.component';
import { AdminComponent } from './admin/admin.component';
import { EditUserDialogComponent } from './dialogs/edit-user-dialog/edit-user-dialog.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthService, FavoriteService, SearchService, TalentService, UserService } from './_services';
import { MainService } from './_services/main.service';
import { ReportsService } from './_services/reports.service';
import { FileService } from './_services/file.service';
import { CoreModule } from './@core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { EditSectorDialogComponent } from './dialogs/edit-sector-dialog/edit-sector-dialog.component';
import { EditRoleDialogComponent } from './dialogs/edit-role-dialog/edit-role-dialog.component';
import { EditUnderRoleDialogComponent } from './dialogs/edit-under-role-dialog/edit-under-role-dialog.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { AddSectionDialogComponent } from './dialogs/add-section-dialog/add-section-dialog.component';
import { AddRoleDialogComponent } from './dialogs/add-role-dialog/add-role-dialog.component';
import { LikeService } from './_services/like.service';
import { ConnectService } from './_services/connect.service';
import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { FavoritedTalentsComponent } from './favorited-talents/favorited-talents.component';
import { HeaderComponent } from './common/header/header.component';
import { ConnectionsComponent } from './connections/connections.component';
import { MatNativeDateModule } from '@angular/material/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { VerificationCodeComponent } from './verification-code/verification-code.component';
import { VerificationPageComponent } from './verification-page/verification-page.component';
import { CodeInputModule } from 'angular-code-input';
import { AdminHeaderComponent } from './common/admin-header/admin-header.component';
import { ReportsComponent } from './reports/reports.component';
import { NoSubscriptionRidirectComponent } from './ri-directions/no-subscription-ridirect/no-subscription-ridirect.component';
import { ForgotPasswordComponent } from './forgot-password-components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password-components/reset-password/reset-password.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HighchartsChartModule } from 'highcharts-angular';
import { RegisterPromocodeComponent } from './register-promocode/register-promocode.component';
import {DROPZONE_CONFIG, DropzoneModule, DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { InfoComponent } from './info/info.component';
import { BrowserAlertDialogComponent } from './dialogs/browser-alert-dialog/browser-alert-dialog.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  addRemoveLinks: true,
  dictRemoveFile: 'Remove',
  maxFiles: 1
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    RegisterComponent,
    OnePagerComponent,
    AdminComponent,
    EditUserDialogComponent,
    EditSectorDialogComponent,
    EditRoleDialogComponent,
    EditUnderRoleDialogComponent,
    AddUserDialogComponent,
    AddSectionDialogComponent,
    AddRoleDialogComponent,
    SavedSearchesComponent,
    FavoritedTalentsComponent,
    HeaderComponent,
    ConnectionsComponent,
    VerificationCodeComponent,
    VerificationPageComponent,
    AdminHeaderComponent,
    ReportsComponent,
    NoSubscriptionRidirectComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterPromocodeComponent,
    ConfirmDialogComponent,
    InfoComponent,
    BrowserAlertDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    CodeInputModule,
    NgxDropzoneModule,
    HighchartsChartModule,
    DropzoneModule,
    MatSnackBarModule
  ],
  providers: [
    HttpClient, 
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }, 
    TalentService, 
    MainService, 
    ReportsService, 
    AuthService, 
    LikeService, 
    FavoriteService, 
    ConnectService, 
    SearchService, 
    UserService, 
    FileService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
