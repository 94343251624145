import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class TalentService {
    constructor(private http: HttpClient) {
    }

    talentsCount(sector_id, role_id = null, canton = null) {
        let getParams = '';

        if (role_id) {
            getParams += `&role_id=${role_id}`
        }
        if (canton) {
            getParams += `&canton=${canton}`
        }

        return this.http.get<any>(`${environment.apiUrl}/talents-count?sector_id=${sector_id}${getParams}`);
    }

    talentsCountAll() {
        return this.http.get<any>(`${environment.apiUrl}/talents-count-all`);
    }

    saveActivity(data) {
        return this.http.post<any>(`${environment.apiUrl}/s-a`, data);
    }
    
    main(data) {
        return this.http.post<any>(`${environment.apiUrl}/main`, data);
    }

    getTalents(sector_id, role_id = null, canton = null) {
        let getParams = '';

        if (role_id) {
            getParams += `&role_id=${role_id}`
        }
        if (canton) {
            getParams += `&canton=${canton}`
        }
        return this.http.get<any>(`${environment.apiUrl}/talents?sector_id=${sector_id}${getParams}`)
    }

    getFavoriteTalents() {
        return this.http.get<any>(`${environment.apiUrl}/favorite-talents`);
    }

    getConnectTalents() {
        return this.http.get<any>(`${environment.apiUrl}/connect-talents`);
    }

    getTalent(id) {
        return this.http.get<any>(`${environment.apiUrl}/talent/${id}`);
    }

    onePagerClosed(data, id) {
        return this.http.post<any>(`${environment.apiUrl}/talent-closed/${id}`, data);
    }

    storeTalent(data) {
        return this.http.post<any>(`${environment.apiUrl}/talent`, data);
    }

    updateTalent(data, id) {
        return this.http.put<any>(`${environment.apiUrl}/talent/${id}`, data);
    }

    deleteTalent(id) {
        return this.http.delete<any>(`${environment.apiUrl}/talent/${id}`);
    }
}
