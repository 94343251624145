<div class="space-between-items mb-3">
    <h2 mat-dialog-title class="mb-0">Branche bearbeiten</h2>
    <div>
        <button mat-icon-button color="#53565a" mat-dialog-close (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="editSectorForm" (ngSubmit)="save()">
        <!-- <div>
            <label class="label-item">Bild <span class="required-label">*</span></label>
            <div class="image-inner">
                <dropzone [config]="uploadPictureConfig"
                          (error)="onUploadError($event)"
                          (sending)="uploadFileType('sector', id, $event)"
                          (success)="onUploadSuccess($event)">
                </dropzone>
            </div>
            <div class="image-wrapper image-upload-preview" *ngIf="userImageThumbnailRootPath">
                <div class="close-image" (click)="deleteImage()"></div>
                <img src="{{userImageThumbnailRootPath}}" alt="">
            </div>
            <div class="required-text mb-2" *ngIf="files.length === 0">
                Bild ist erforderlich
            </div>
        </div> -->

        <div class="inline-flex">
            <div class="input-box-wrapper full-width">
                <label class="label-item">Nr. Reihenfolge <span class="required-label">*</span></label>
                <input type="number" formControlName="order_number" class="input-item available-width" [class.required-input]="(editSectorForm.get('order_number').touched && editSectorForm.get('order_number').invalid)">
                <div class="required-text" *ngIf="(editSectorForm.get('order_number').touched && editSectorForm.get('order_number').invalid)">
                    Nr. Reihenfolge ist erforderlich
                </div>
            </div>
        </div>
        <div class="inline-flex">
            <div class="input-box-wrapper full-width">
                <label class="label-item">Branche <span class="required-label">*</span></label>
                <input type="text" formControlName="name" class="input-item available-width" [class.required-input]="(editSectorForm.get('name').touched && editSectorForm.get('name').invalid)">
                <div class="required-text" *ngIf="(editSectorForm.get('name').touched && editSectorForm.get('name').invalid)">
                    Branche ist erforderlich
                </div>
            </div>
        </div>
        <div class="centered full-width">
            <button class="btn-elem mb-2" [disabled]="!editSectorForm.valid">speichern</button>
        </div>
    </form> 
</mat-dialog-content>