<div class="centered-flex">
    <div>
        <img src="../../../assets/images/pse_logo.jpg" alt="">
        <div class="centered-div" *ngIf="!validSubmit">
            <p class="text big-text">Bitte E-Mail eingeben</p>
            <p class="text">Eine E-Mail mit Rücksetzungslink für das Passwort wird an diese Adresse geschickt.</p>
            <form [formGroup]="forgotPasswordForm">
                <div class="input-box-wrapper">
                    <label class="label-item">E-Mail</label>
                    <input type="text" formControlName="email" class="input-item" [class.required-input]="(submitted && forgotPasswordForm.get('email').invalid) || (forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').invalid)">
                    <div class="required-text" *ngIf="(submitted && forgotPasswordForm.get('email').invalid) || (forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').invalid)">
                        E-Mail ist erforderlich
                    </div>
                </div>
                <div class="required-text mb-2" *ngIf="false">
                    diese E-Mail existiert nicht
                </div>
                <button type="button" class="btn-el" (click)="resetPassword()">senden</button>
            </form>
        </div>
        <div class="centered-div" *ngIf="linkSentSuccessfully">
            <p class="text big-text">Bitte E-Mail prüfen im Posteingang von: {{forgotPasswordForm.get('email').value}}</p>
            <p class="text">Bitte "erneut senden" drücken, sofern keine E-Mail mit Rücksetzungslink angekommen ist.</p>
            <div class="centered-items mb-15">
                <button type="submit" class="btn-el mr-1" (click)="changeEmail()">E-Mail bearbeiten</button>
                <button type="submit" class="btn-el ml-1" (click)="resetPassword(); resendPassword()">erneut senden</button>
            </div>
            <div *ngIf="isPasswordResent" class="required-text">
                Es wurde ein neuer Code angefordert. Die Zustellung kann (auf Grund Ihrer E-Mail-Sicherheitseinstellungen) einige Zeit in Anspruch nehmen.
              </div>
        </div>
    </div>
</div>